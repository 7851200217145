@use "src/Styles/_vars.scss" as c;

.candidatures_recues {
  background-color: c.$fond-page;
  padding: 4rem 7rem;
  h1 {
    font-family: c.$font-gros-titres;
    color: c.$noir-rp;
    font-size: xx-large !important;
    margin-top: 1rem;
  }
  h3 {
    font-size: medium;
  }
  .title_candidatures {
    font-size: large;
    font-weight: 700;
    margin-top: 2.5rem;
  }
  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__body {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .divider {
    border-top: 2px solid darkgray;
    padding-bottom: 1rem;
    width: 60%;
    margin: 0 20%;
  }
}

.candidature_container__recap {
  display: flex;
  // Affiche un par ligne au centre
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

// Media téléphone
@media screen and (max-width: 768px) {
  .candidatures_recues {
    padding: 2rem 1rem;
  }
}
