@use "src/Styles/_vars.scss" as c;

.modal_charte__boutons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.charte__container {
  &-text {
    // Centrer
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-bottom: 2svh;
    color: c.$bleu-paris1;

    &:hover {
      text-decoration: underline;
    }

    iframe {
      width: 100%;
      height: 100%;
      margin-bottom: 5svh;
    }
  }
}
