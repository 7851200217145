@use "src/Styles/_vars.scss" as c;
// boutons
.buttonsBarDisconnected {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 7rem;
  margin-bottom: 4rem;
  padding: 1rem;
  min-height: 8rem;
  width: 90%;
  margin: 5% 5%;
  background-color: none !important;

  h2 {
    font-family: c.$font-sous-menu;
    color: white;
    line-height: 2.5rem;
    margin-bottom: 5rem;
    font-size: xx-large;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;
    top: 0rem;
    margin-bottom: 2rem;
  }

  .bouton-visible {
    margin-bottom: 1rem;
    // changement de couleur pour la page hors connexion
    .btn-blanc {
      color: c.$blanc;
      width: 20rem !important;
      font-family: c.$font-sous-menu;
      border-color: c.$blanc;
      background-color: transparent;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      &:hover {
        color: c.$bordeau-commun !important;
        background-color: c.$blanc;
        cursor: pointer;
      }
    }
    .p-dropdown.p-component.p-inputwrapper {
      color: c.$blanc;
      // border-radius: 30px;
      width: 12.5rem !important;
      font-family: c.$font-sous-menu;
      border-color: c.$blanc;
      background-color: transparent;
      transition: all 0.5s;

      &:hover {
        color: c.$bordeau-commun !important;
        background-color: c.$blanc;
        cursor: pointer;
        .p-dropdown-trigger-icon.p-clickable.pi {
          color: c.$bordeau-commun;
        }
      }
      .p-dropdown-label.p-inputtext.p-placeholder {
        color: white;
        font-family: c.$font-sous-menu;
      }
      .p-dropdown-label.p-inputtext.p-placeholder:hover {
        color: c.$bordeau-commun !important;
      }

      .p-dropdown-trigger-icon.p-clickable.pi {
        color: white;
      }
    }
  }
  .icon-button {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: c.$small) {
  .buttonsBarDisconnected {
    width: 80%;
    margin: 5% 10%;
    h2 {
      margin-top: 0;
      margin-bottom: 1rem;
      // padding: 1rem;
      line-height: 3rem;
      width: 100%;
      background-color: none !important;
      font-size: large;
    }
    .buttons {
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0;
      // width: 100%;
      .bouton-visible {
        padding: 0;
        .btn-blanc {
          width: fit-content !important;
        }
      }
      .bouton-visible.personnel {
        // width: 22rem !important;
        // width: 100% !important;
        .btn-blanc {
          width: 100% !important;
        }
      }
    }

    .buttons_bar {
      margin-bottom: 2rem;
    }
  }
}
@media screen and (max-width: c.$tiny) {
  .buttonsBarDisconnected {
    width: 95%;
    // margin: 2rem 0;
    margin: 2.5% 2.5%;
    h2 {
      // margin-top: 1rem;
      // margin-bottom: 1rem;
      // padding: 1rem;
      // line-height: 3rem;
      // width: 100%;
      // background-color: none !important;
      // font-size: large;
    }
    .buttons {
      justify-content: center;
      .bouton-visible {
        padding: 0;
        // width: 90%;
      }
    }

    .buttons_bar {
      margin-bottom: 2rem;
    }
  }
}
