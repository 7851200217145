@use "src/Styles/_vars.scss" as c;

h1 {
  font-size: medium !important;
}
h2 {
  padding: 0.5rem;
}

p {
  font-family: c.$font-global !important;
}
ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
a {
  text-decoration: none !important;
  color: unset !important;
}

.border {
  border: none !important;
}

.p-dropdown-label.p-inputtext.p-placeholder,
.p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext,
.p-component,
.p-dropdown-label.p-inputtext.p-placeholder {
  font-family: c.$font-global !important;
  font-size: medium !important;
}

@media screen and (max-width: c.$small) {
  .p-menuitem-text {
    display: unset;
  }
}

// permet d'avoir le trait au dessus des titres des pages
.h1_trait_dessus {
  font-family: "font-gros-titres";
  color: #00326e;
  font-size: xx-large !important;
  padding-top: 4rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  &::before {
    display: block;
    content: "";
    width: 100px;
    height: 2px;
    background-color: c.$noir-rp;
    margin-bottom: 1rem;
  }
}

.article_button {
  display: flex;
  justify-content: center;
}

// ------------------------style de la navbar
.styleNavBarActive {
  .navBarProfil {
    display: flex;
    justify-content: center;
    &__tabMenu {
      display: flex;
      justify-content: center;
    }

    ul.p-tabmenu-nav.p-reset {
      border: none !important;
      overflow: hidden;
      border-radius: 0.8rem;

      .p-tabmenuitem {
        width: 13rem;
        border-left: 1px solid lightgray;
        text-align: center;
        a {
          flex-direction: column;
          padding: 1.5rem 1rem !important;
          border: none !important;
          background-color: white;
          box-shadow: none !important;
          .p-menuitem-icon {
            color: c.$gris-picto;
          }
          .p-menuitem-text {
            margin-top: 0.5rem;
            color: c.$gris-picto;
            font-family: c.$font-sous-menu;
            font-size: c.$font-size-small;
            width: 10rem;
            &:hover {
              color: c.$noir-rp;
            }
          }
        }
        &:first-child {
          border-left: none;
        }
      }
      .p-tabmenuitem.p-highlight {
        .p-menuitem-link {
          background-color: c.$noir-rp !important;
          color: white !important;
          border: none;
          border-radius: 0rem !important;
          box-shadow: none;
          .p-menuitem-text,
          .p-menuitem-icon {
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .styleNavBarActive {
    .navBarProfil {
      padding: 0;
      ul.p-tabmenu-nav.p-reset {
        width: 97%;
        overflow: unset;
        border-radius: unset;
        .p-tabmenuitem a {
          padding: 0.5rem 0.5rem 1rem !important;
        }
      }
    }
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0rem;
  }
}

// modifications d'affichage navBar pour mobile
@media screen and (max-width: c.$medium) {
  .styleNavBarActive {
    .navBarProfil {
      padding: 0;
      .p-scrollpanel-content {
        width: 100%;
        overflow: unset;
      }
      ul.p-tabmenu-nav.p-reset {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        .p-tabmenuitem {
          width: fit-content;
          min-width: 11rem;
        }
        .p-tabmenuitem.p-highlight {
          .p-menuitem-link {
            background-color: white !important;
            color: white !important;
            border: none;
            border-radius: 0rem !important;
            .p-menuitem-text,
            .p-menuitem-icon {
              color: c.$noir-rp;
            }
          }
        }
      }
    }
  }
}

//? Effet zoom et shadow au hover sur une card (Ajouter la classe card pour se faire)
.cardHover {
  transition-duration: 0.2s;
  margin-bottom: 2rem;
  box-shadow: 2px 3px 6px lightgrey;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

// modification des composants primereact

.p-button.p-component.p-confirm-popup-accept.p-button-sm {
  background: c.$noir-rp;
  border-radius: 30px;
  color: white;
  border: 1px solid c.$noir-rp;
  width: 6rem;
  height: 3rem;
}
.p-button.p-component.p-confirm-popup-reject.p-button-sm.p-button-text {
  border: 1px solid c.$noir-rp;
  box-sizing: border-box;
  border-radius: 30px;
  width: 6rem;
  height: 3rem;
  color: c.$noir-rp;
}

// --------- composant file upload
.p-fileupload.p-fileupload-advenced.p-component {
  display: flex !important;
}
.p-button.p-fileupload-choose.p-component {
  background-color: c.$fond-page !important;
  border-radius: unset !important;
  color: c.$noir-rp;
  border: unset !important;
  font-size: small;
  &:hover {
    color: c.$noir-rp !important;
    background-color: c.$fond-page !important;
  }
  &:focus {
    border: unset !important;
  }
}

.p-button.p-fileupload-choose.p-component:hover {
  cursor: pointer;
}
.p-button.p-component.p-disabled {
  color: c.$gris-picto;
  &:hover {
    cursor: pointer;
  }
}

.p-fileupload .p-fileupload-content {
  border: none !important;
  background: transparent !important;
  height: fit-content;
  padding: 0 !important;
  font-size: small;
  .p-button.p-button-icon-only {
    width: 2rem;
    height: 2rem;
    display: none;
  }
  .p-progressbar.p-component.p-progressbar-determinate {
    display: none;
  }
}
.p-fileupload.p-fileupload-advanced.p-component {
  margin-top: 0.5rem;
}
.p-fileupload-buttonbar {
  .p-button.p-component.p-disabled {
    .p-button-icon.p-c.p-button-icon-left.pi.pi-upload {
      display: none;
    }
    .p-button-icon.p-c.p-button-icon-left.pi.pi-times {
      display: none;
    }
  }
  .p-button-icon.p-button-icon-left.p-clickable.pi.pi-fw.pi-plus {
    display: none;
  }
  .p-button.p-component {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    font-size: small;
    &:hover {
      background-color: transparent !important;
      color: c.$noir-rp !important;
    }
  }
  .p-button.p-component.bouton_valider {
    background-color: c.$vert-groupe !important;
    color: white;
    width: 2rem;
    height: 2rem;
    &:hover {
      color: white !important;
    }
    .p-button-icon.p-c.p-button-icon-left.pi.pi-check {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
  // pour que dans le dépôt d'offres on n'aie pas les composants qui s'affichent s'ils sont pas actifs
  .p-button.p-component.p-disabled {
    display: none;
  }
  .p-button.p-component.bouton_supprimer {
    background-color: c.$rouge-groupe !important;
    color: white;
    width: 2rem;
    height: 2rem;
    &:hover {
      color: white !important;
    }
    .p-button-icon.p-c.p-button-icon-left.pi.pi-times {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  .p-button-label {
    font-weight: unset;
  }
  button.p-button.p-component.p-disabled {
    .p-button .p-button-icon-left {
      display: none;
    }
  }
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: unset !important;
  padding-top: unset;
  padding-bottom: unset;
  padding-left: unset;
}
.p-fileupload .p-fileupload-buttonbar {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
}
// permet d'avoir le trait au dessous des titres dans le footer
.h1_trait_dessous::before {
  display: block;
  content: "";
  width: 20px;
  height: 2px;
  background-color: white;
  // margin-top: 2rem;
  position: relative;
  top: 2rem;
}
// ------------------style boutons
.p-button.p-component {
  border: 1px solid c.$noir-rp;
  box-sizing: border-box;
  border-radius: 30px;
  color: c.$noir-rp;
  background-color: white;
}
.p-button.p-component:hover {
  color: white !important;
  background-color: c.$noir-rp !important;
}

.p-button.p-component.p-disabled {
  opacity: 1 !important;
  font-size: small !important;
  &:hover {
    opacity: none !important;
    cursor: pointer;
    background-color: c.$noir-rp;
    color: white;
  }
}

// -----------------------style accordéon
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent !important;
  border-color: transparent !important;
  padding: 1.25rem 0 0 0 !important;
  display: flex;
  align-items: center;
}
.p-accordion-header-text,
.p-accordion .p-accordion-header .p-accordion-header-link {
  color: c.$noir-rp !important;
}
.p-accordion .p-accordion-content {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
}
.p-accordion-tab {
  margin-left: 0;
}

// -------------- style dropdown, multiselect, inputtext similaires pour les formulaires et barres de recherche
.p-multiselect.p-component.p-inputwrapper,
.p-dropdown.p-component.p-inputwrapper,
.p-inputtext.p-component.box__input,
.p-inputtext.p-component {
  border-radius: 2rem;
  height: 2.5rem;
  // box-shadow: 0px 2px 0.2px lightgrey;
  align-items: center;
  &:enabled:hover {
    border: none;
  }
  &:hover {
    border: none;
  }
  &:focus {
    box-shadow: none;
  }
}
.p-inputtext.p-component.p-placeholder,
.p-multiselect-label.p-placeholder,
.p-dropdown-label.p-inputtext.p-placeholder,
.p-inputtext {
  color: c.$gris-picto;
  padding-left: 0.5rem;
  margin-left: 0.75rem;
  font-family: c.$font-global;
}
.p-multiselect {
  border: none !important;
  &:hover {
    border: none;
  }
  &:focus {
    box-shadow: none;
  }
}

// ------------------------style dropdown
.p-dropdown.p-component.p-inputwrapper {
  color: c.$blanc;
  width: 12.5rem;
  font-family: c.$font-sous-menu;
  border-color: c.$blanc;
  transition: all 0.5s;
  border: none;

  &:hover {
    background-color: c.$blanc;
    cursor: pointer;
  }
}

//----------------style multiselect
.p-multiselect .p-multiselect-label {
  padding: 0.75rem 0.75rem 0.75rem 0.5rem !important;
}

//-------------------style inputtext area
.p-inputtextarea.p-inputtext.p-component {
  height: unset;
  border-radius: 0.33rem;
  border: none;
  &:hover {
    border: none;
  }
  &:focus {
    box-shadow: none;
  }
}

// style des calendriers
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  border: none !important;
  box-shadow: 0px 2px 0.2px lightgray !important;
  height: 2.5rem !important;
  background-color: white;
  border-radius: 30rem;
  text-decoration: none;
  margin-top: 0.5rem;
  &:hover {
    border: 1px solid c.$noir-rp !important;
  }
}

.p-dialog .p-dialog-content {
  padding-top: 1rem !important;
  height: fit-content;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
}

.depot_offre_formulaire {
  &__container {
    .question {
      .import {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .import_button {
          color: c.$noir-rp;
          width: 25%;
          text-align: left;
          border: none;
          padding-left: unset;
          background-color: c.$fond-page;
          font-size: small;
        }
      }
    }
    .p-multiselect-panel.p-component.p-connected-overlay-enter-done {
      .p-checkbox.p-component {
        margin-top: 0 !important;
      }
    }
  }
}

// style de la checkbox
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: c.$noir-rp !important;
  background: c.$noir-rp !important;
  &:hover {
    border-color: c.$noir-rp !important;
  }
}
.p-checkbox.p-checkbox-checked {
  box-shadow: none !important;
}
.p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: c.$noir-rp !important;
  box-shadow: none !important;
}

.ql-container {
  background-color: white;
}
