@use "src/Styles/_vars.scss" as c;

.modal {
  //?Header
  .p-dialog-header {
    background-color: c.$noir-rp !important;
    .p-dialog-title {
      font-family: c.$font-sous-menu !important;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.1rem !important;
    }
  }
  .btn-bleu {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .btn-bleu:hover {
    background-color: white;
    color: c.$noir-rp;
  }
  .btn-blanc {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  // .btn-blanc:hover {
  //   cursor: pointer;
  //   background-color: c.$noir-rp;
  //   color: white;
  // }
  p {
    margin: 0.5rem 0 0.5rem 0;
  }
  .p-inputtextarea {
    margin: 0.5rem 0 0 0;
    width: 80%;
    height: 10rem;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
