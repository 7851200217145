@use "src/Styles/_vars.scss" as c;

.gestion_ressources {
  padding: 0 7rem;
  background-color: white;
  &__title {
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      margin-bottom: 0.5rem;
    }
    &__filtres {
      display: flex;
      justify-content: flex-end;
      .btn-blanc {
        margin: 1rem 0 0 2rem;
        height: 2.5rem;
        width: fit-content !important;
      }
    }
  }
  &__container {
    padding: 2rem 0 0 0;
    .ressource.cardHover {
      box-shadow: 2px 3px 6px lightgray;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .gestion_ressources {
    padding: 0 3rem;
  }
}

@media screen and (max-width: c.$small) {
  .gestion_ressources {
    padding: 0 1rem;
    &__title {
      flex-direction: column;
      &__filtres {
        flex-direction: column;
        .btn-blanc {
          margin: 1rem 0 0 0;
          height: 2.5rem;
          width: fit-content !important;
        }
      }
    }
  }
}
