@use "src/Styles/_vars.scss" as c;

.btn-rond {
  background: c.$blanc;
  border: none;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  color: c.$noir-rp;
  transition: all 0.5s;

  &:hover {
    color: c.$blanc;
    background: c.$noir-rp;
    cursor: pointer;
  }
}
