@use "src/Styles/_vars.scss" as c;

// Style pour le champ numéro de téléphone
.PhoneInput {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1rem;
  // Champ input
  input.PhoneInputInput {
    width: 33% !important;
    min-width: 33% !important;
  }

  .PhoneInputCountry {
    // On cache le select du pays
    select {
      display: none;
    }

    // Redimensionnement de l'icône du pays
    .PhoneInputCountryIcon {
      img {
        width: 3rem;
        height: 3rem;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.modal-register {
  .p-dialog-content {
    padding: 0 !important;
  }

  #companyName,
  #civility,
  #sector,
  #role,
  #siret {
    width: 33% !important;
  }

  #structure,
  #size {
    width: 100% !important;
  }

  #companyUrl {
    width: 50% !important;
  }

  .form-demo {
    background-color: c.$fond-page;
    padding: 2rem;

    form {
      margin-top: 1rem;

      .ask_connexion_name,
      .ask_connexion_coordinates,
      .ask_connexion_company,
      .checkboxes {
        display: flex;
        flex-direction: row;
      }
    }

    .field {
      margin: 0 0 1.5rem 1rem;

      h2 {
        margin-bottom: 0.5rem;
        font-family: "font-sous-menu";
        color: var(--main-color-univ);
        padding-bottom: 0;
        font-size: medium;
      }
    }
  }

  .company_icon {
    display: flex;
    flex-direction: row;
    font-size: medium;
    margin: 0 0 1.5rem 1rem;
    font-family: "font-sous-menu";

    &.not_found {
      color: c.$erreur;
    }

    &.already_partner {
      color: c.$vert-icon;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
    }
  }

  .recruiter__request {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .pi.pi-check-circle {
        margin: 2vh 0;
      }

      button {
        margin: 2vh 0;
      }
    }
  }

  .identification_number {
    width: 30%;
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    .form-demo {
      form {
        .ask_connexion_name,
        .ask_connexion_coordinates,
        .ask_connexion_company,
        .checkboxes {
          flex-direction: column;
        }
      }
    }
    .PhoneInput {
      input {
        width: 75% !important;
      }
    }

    .identification_number {
      width: 100%;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}

.field {
  .checkbox_association,
  .checkbox_international,
  .checkbox_french {
    .p-disabled {
      opacity: 1;
    }
  }
}

.options-available {
  border-color: green !important; // change the border color when new options are available
  box-shadow: 0 0 5px green !important;
}
