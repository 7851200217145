@use "src/Styles/_vars.scss" as c;

.p-divider .p-divider-content {
  background-color: c.$fond-page !important;
}
.p-tag {
  background-color: c.$noir-rp !important;
  font-size: medium !important;
}

.p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
  margin-top: 2rem;
}

.actus_title {
  padding: 0 7rem;
}

.form_param_confid {
  label {
    margin-left: 0.5rem;
  }
}

.charter__view {
  color: c.$bleu-paris1;
  margin: 3svh 0;
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: c.$small) {
  .actus_title {
    padding: 0 1rem;
  }
}
