@use "src/Styles/_vars.scss" as c;

.p-button.p-component.bouton_annuler {
  display: none;
}

.depot_offre_formulaire {
  background-color: c.$fond-page;
  padding: 0 7rem;

  &__title {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;
    align-items: baseline;
    h1 {
      padding-bottom: 2rem;
      color: c.$bleu-paris1;
      font-family: c.$font-gros-titres;
      font-size: xx-large !important;
    }
    button {
      float: right;
      border: 1px solid c.$bleu-paris1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 30px;
      width: fit-content;
      height: 2.5rem;
      color: c.$bleu-paris1;
      transition: all 0.5s;
      padding: 0rem 1.5rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      svg {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
    button:hover {
      cursor: pointer;
      background-color: c.$bleu-paris1;
      color: white;
    }
  }

  h1 {
    font-family: c.$font-gros-titres;
    color: c.$bleu-paris1;
    font-size: xx-large !important;
    padding-bottom: 2rem;
  }
  h2 {
    font-family: c.$font-sous-menu;
    color: c.$bleu-paris1;
    padding-bottom: 0;
    font-size: medium;
  }
  h2.p-error {
    font-family: c.$font-sous-menu;
    color: red;
    padding-bottom: 0;
    font-size: medium;
  }
  small {
    display: block;
  }
  // boutons en bas de page
  &__boutons {
    margin: 3rem 0 4rem 0;
    display: flex;
    justify-content: flex-start;
    .btn-bleu,
    .btn-blanc {
      margin: 0;
      margin-right: 1rem;
    }
    &__modal {
      padding: 0 1rem 0 1rem;
      display: flex;
      .btn-bleu {
        margin-right: 1rem;
      }
    }
    .waiting_button {
      width: 10rem;
      .loaderContainer {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .loaderContainer__loader {
          width: 2rem;
          height: 2rem;
        }
        .loaderContainer__loader::after {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8%;
    form {
      width: 100%;
    }
    .html_rich {
      width: 100%;
      margin-bottom: 5vh;
    }
    // .universities {
    //   display: grid;
    //   grid-template-columns: repeat(4, minmax(9rem, auto));
    //   gap: 1rem 1rem;
    //   width: fit-content;
    //   img {
    //     padding: 0.5rem;
    //     cursor: pointer;
    //     width: 14rem;
    //     height: 8rem;
    //     margin-top: 0.5rem;
    //     margin-right: 2rem;
    //     box-shadow: 2px 3px 6px lightgray;
    //     border-radius: 1rem;
    //     &.isSelected {
    //       border: 2px solid c.$bleu-paris1;
    //     }
    //   }
    // }
    .warning_depot {
      color: red;
    }
    .question.checkbox {
      display: flex;
      flex-direction: row;
      label {
        margin-top: 0;
        display: flex;
        align-items: center;
      }
      svg {
        margin-right: 0.5rem;
      }
    }
    .question {
      width: 100%;
      font-family: c.$font-sous-menu;
      padding-bottom: 2rem;
      display: flex;
      flex-direction: column;
      position: relative;
      .btn-blanc {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      a {
        font-family: c.$font-global;
        cursor: pointer;
        .size {
          font-size: 1.5rem;
        }
      }

      .logos_univ {
        margin-top: 1rem;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;

        .logo_univ_1 {
          border: 1px solid c.$gris;
          border-radius: 0.5rem;
          background-color: white;
          width: 6rem;
          height: 5rem;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img {
            width: 5rem;
            height: fit-content;
          }
        }
        .logo_hover {
          border: 2px solid c.$bleu-paris1;
          border-radius: 0.5rem;
          background-color: white;
          width: 6rem;
          height: 5rem;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img {
            width: 5rem;
            height: fit-content;
          }
        }
      }

      label {
        font-family: c.$font-global;
      }
      .salaire {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;

        .input {
          width: 40%;
        }
        &__montant {
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          width: 45%;
          position: relative;
        }
      }

      &__input {
        border-radius: 5rem;
        margin-top: 1rem;
        padding: 1rem 1rem 1rem 2.5rem;
        border: none;
        font-size: 1rem;
        width: 100%;
      }
      .p-dropdown.p-component.p-inputwrapper.box__input {
        border: 1px solid transparent;
        border-radius: 2rem !important;
        font-family: c.$font-global;
        width: 100% !important;
      }
      .p-multiselect.p-componentp-inputwrapper.box__input {
        border: 1px solid transparent;
        border-radius: 2rem !important;
        font-family: c.$font-global;
      }

      .p-inputtextarea {
        font-family: c.$font-global;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
      }
      p {
        margin-top: 0.5rem;
      }
      .q-small {
        width: 43%;
        .p-dropdown.p-component.p-inputwrapper.box__input {
          width: 100%;
        }
        .p-multiselect.p-component.p-inputwrapper.box__input {
          width: 100%;
        }
      }
    }
    .description {
      width: 100%;
      padding-bottom: 2rem;
    }
    // gestion des lignes avec deux champs par colonnes
    .depot_columns {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      width: 100%;
      .question {
        width: 43%;
        margin-right: 3rem;
        label {
          margin-bottom: 0.5rem;
        }
      }
      .question_salaire {
        width: 10%;
        label {
          margin-bottom: 0.5rem;
        }
        .salary-toggle_button {
          min-width: 12rem;
          letter-spacing: 1px;
          margin-top: 0.1rem !important;
          margin-bottom: 1rem !important;

          &:hover {
            span {
              color: white !important;
            }
          }
        }
        .p-togglebutton.p-button.p-highlight {
          background: c.$bleu-paris1;
          border-color: c.$bleu-paris1;
        }
        .p-button:focus {
          box-shadow: none;
        }
        &__slider {
          width: 30%;
          display: flex;
          align-items: center;
          margin-left: 5rem;
        }
      }
      // gestion des lignes avec trois champs par colonne
      &__trois .questions_location {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        .p-dropdown-label.p-placeholder {
          margin-top: 0.25rem;
        }
        .p-multiselect-label.p-placeholder {
          margin-top: 0.25rem;
        }
        .question:hover {
          .listOfCities {
            display: block !important;
            border-radius: 1rem;
            padding: 0.25rem;
          }
        }
        .question {
          width: fit-content;
          margin-right: 3rem;
          .p-dropdown.p-component.p-inputwrapper.box__input {
            width: 100% !important;
          }

          label {
            margin-bottom: 0.5rem;
          }
          .listOfCities {
            background-color: white;
            display: none;
            position: absolute;
            margin-top: 4.5rem;
            z-index: 1000;
            .searchedCity {
              display: flex;
              flex-direction: column;
              padding: 0.5rem;
              cursor: pointer;
              &:hover {
                cursor: pointer;
                .city,
                .department {
                  color: c.$bleu-paris1;
                }
              }
              .department {
                color: #6c757d;
                font-family: "font-global";
                font-size: medium;
              }
            }
          }
        }
      }
    }
    .fileupload_columns {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .question {
        width: 43% !important;
        label {
          margin-bottom: 0.5rem;
        }
      }
    }
    .spaceLabel {
      margin-left: 1rem;
    }
    .p-dropdown.p-component.p-inputwrapper.box__input {
      background-color: white !important;
    }
    .p-multiselect.p-component.p-inputwrapper.box__input {
      background-color: white !important;
    }
    .p-dropdown.p-component.p-inputwrapper.p-inputwrapper {
      border: 1px solid transparent;

      &:hover {
        border: 1px solid c.$bleu-paris1;
      }
    }
  }
  // hover de tous les champs
  .box__input {
    &:hover {
      border: none !important;
    }
  }
  // gère les passages à la ligne
  .br_salaire,
  .br_domaine,
  .br_localisation {
    display: none;
  }
  .modal {
    .pi-send {
      color: c.$vert-icon;
      display: flex;
      justify-content: center;
      font-size: 4rem;
    }
    .pi-trash {
      color: c.$vert-icon;
      display: flex;
      justify-content: center;
      font-size: 4rem;
    }
  }

  .modal_offre {
    height: 50rem;
  }
}
.offre_main {
  &__description {
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    h1 {
      font-family: c.$font-gros-titres;
      font-size: x-large;
      color: c.$bleu-paris1;
      margin: 2rem 1rem 2rem 1rem;
    }
    p {
      margin: 0 1rem 0.5rem 1rem;
      text-justify: inter-word;
      span {
        font-family: c.$font-gros-titres;
        color: c.$bleu-paris1;
      }
    }
  }
}
.prev_offre {
  .offre {
    margin-top: 2rem;
    display: flex;
    align-self: center;
  }
}

.salary-toggle_button {
  margin-top: 5vh !important;
  margin-bottom: 5vh !important;
  width: 30%;
  span {
    font-size: smaller;
  }
}

.p-slider .p-slider-range {
  background-color: c.$bleu-paris1 !important;
}
.p-slider .p-slider-handle {
  border: 2px solid c.$bleu-paris1 !important;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: c.$bleu-paris1 !important;
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$medium) {
  .depot_offre_formulaire__container {
    .depot_columns {
      display: inline;
      .question,
      .question.salaire {
        width: 100%;
      }
      .question_salaire__slider {
        margin-top: auto;
        margin-bottom: 5vh;
        width: 100%;
      }
      &__trois {
        display: inline;
        .question {
          width: 100% !important;
        }
      }
    }
    .fileupload_columns {
      display: inline;
      .question,
      .question.salaire {
        width: 100%;
      }
    }
    .question {
      .q-small {
        width: 100% !important;
        .p-dropdown.p-component.p-inputwrapper.box__input {
          width: 100% !important;
        }
        .p-multiselect.p-component.p-inputwrapper.box__input {
          width: 100% !important;
        }
      }
    }
    .question {
      width: 100% !important;
    }
    .q-small {
      .p-dropdown.p-component.p-inputwrapper.box__input {
        width: 100%;
      }
      .p-multiselect.p-component.p-inputwrapper.box__input {
        width: 100%;
      }
    }
    br {
      display: none;
    }
  }

  .salary-toggle_button {
    margin-top: 2vh !important;
    margin-bottom: 4vh !important;
    width: 60%;
    span {
      font-size: smaller;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .depot_offre_formulaire {
    &__title {
      &__filtres {
        button {
          font-size: small;
        }
      }
    }
    padding: 0 1rem;
    h1 {
      padding: 2rem 0;
    }
    &__container {
      width: 100%;
      padding: 0;
    }
    .salaire {
      display: flex;
      justify-content: space-between;
      flex-direction: column !important;
    }
    &__boutons {
      display: flex;
      flex-direction: column;
      .btn-blanc {
        margin: 0.5rem;
      }
      .btn-bleu {
        margin: 0.5rem;
      }
    }
    &__container {
      .br_localisation,
      .br_domaine,
      .br_salaire,
      .h2_salaire {
        display: none !important;
      }
      .question {
        width: 100% !important;
        .q-small .p-dropdown.p-component.p-inputwrapper.box__input {
          width: 100% !important;
        }
        .q-small .p-multiselect.p-component.p-inputwrapper.box__input {
          width: 100% !important;
        }
      }
    }
  }
}
