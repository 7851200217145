@use "src/Styles/_vars.scss" as c;

.header {
  &__bandeau {
    &__user {
      &__icon {
        width: 2rem;
        height: 1rem;
      }
    }
  }
}

.description {
  .universites__connexion {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  img {
    width: 10rem;
    padding: 1rem;
    margin: 1.5rem 1rem;
  }
  .logos_univ {
  }
  a {
    width: fit-content;
  }
  .logo_univ_1 {
    margin: 0.5rem 0;
    width: fit-content;
  }
  .logo_hover {
    width: fit-content;
    margin: 0.5rem 0;
    border: 1px solid c.$noir-rp;
    border-radius: 0.33rem;
  }
}
