.sized__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  &.normal {
    border-radius: 0.33rem 0.33rem 0 0;
  }
  &.rounded_image {
    border-radius: 50%;
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(4px);
    opacity: 0.6;
  }
  &__image {
    z-index: 900;
    max-width: 100%;
    object-fit: cover;
    font-weight: 700;
  }
}
