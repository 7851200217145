@use "src/Styles/_vars.scss" as c;

.management_new_event_offer_filtres__top {
  display: flex;
  flex-direction: row;
  &__createBtn {
    margin-left: 1rem;
    min-width: fit-content;
    .btn-blanc {
      height: 2.5rem !important;
    }
  }
}

@media screen and (max-width: c.$medium) {
  .management_new_event_offer_filtres__top {
    flex-direction: column;
    &__createBtn {
      margin-left: 0 !important;
    }
  }
}
