@use "src/Styles/_vars.scss" as c;

.navigation {
  a {
    height: 100%;
  }
  .activeLink {
    position: relative;
    &::after {
      content: "";
      display: block;
      background-color: c.$blanc;
      height: 0.1rem;
      position: absolute;
      width: 100%;
      top: 2.2rem;
    }
  }
}
.annuaire__button {
  position: relative;
  color: white;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  font-family: c.$font-global;
  padding: 0.5rem 0;
  border-radius: 2rem;
  transition: all 0.5s;
  font-size: c.$font-size-normal;
}

.annuaireInterface {
  position: absolute;
  z-index: 99999;
  width: 16rem;
  background-color: c.$blanc;
  color: c.$bleu-commun;
  padding: 0.5rem;
  border-radius: 0.2rem;
  top: 2rem;
  left: -5.2rem;
  right: 0;

  .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
    margin-top: 0;
  }
  .p-divider-horizontal.p-divider-left {
    margin: 0 10%;
    width: 80%;
  }
  li {
    margin: 0;
    width: 95%;
    padding: 0 0.5rem;
    a {
      width: 82%;
      margin-bottom: 0;
      font-size: 0.9rem;
    }
  }
  li,
  a {
    padding: 0.5rem;
    color: c.$bleu-commun !important;
    text-align: left;

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
  p {
    color: c.$gris-picto;
    display: flex;
    text-align: start;
    justify-content: left;
    padding-top: 0.5rem;
  }
  .annuaireBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .border {
    border: none !important;
    border-bottom: 1px solid black !important;
  }
  .pi-user {
    margin: 0rem 0rem 0 0.2rem;
    color: c.$gris-picto;
  }
  .laurier {
    margin: 0rem 0rem 0 0rem;
    color: c.$gris-picto;
    width: 1.5rem;
    height: 1.5rem;
    color: c.$gris-picto;
  }
  .pi-building,
  .pi-bookmark,
  .pi-calendar,
  .pi-book,
  .pi-check,
  .pi-download,
  .pi-list {
    margin: 0rem 0rem 0 0.7rem;
    color: c.$gris-picto;
  }
  .pi-download {
    margin-bottom: 1rem;
    margin-left: 0;
  }
  .pi-star {
    display: none;
  }
  .pi-building {
    margin-left: 0rem;
  }
}

.navBarProfil {
  .p-menuitem-icon.pi-star {
    visibility: hidden;
  }
  .p-menuitem-icon.pi-star + span {
    position: relative;
  }
  .p-tabmenuitem .p-menuitem-icon.pi-star + span::before {
    display: flex;
    content: url("../../../assets/laurier_gris_petit.webp");
    position: relative;
    width: 0.2rem;
    height: 0.2rem;
    z-index: 1000;
    top: -1.9rem;
    left: 4.5rem;
  }
  .p-tabmenuitem.p-highlight .p-menuitem-icon.pi-star + span::before {
    display: block;
    content: url("../../../assets/laurier_blanc_petit.webp");
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    z-index: 1000;
    top: -1.9rem;
    left: 4.5rem;
  }
}
