@use "src/Styles/_vars.scss" as c;

.CVs__container {
  background-color: c.$fond-page;
  padding-bottom: 5rem;

  &__title {
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      padding-top: 4rem;
    }
  }
  &__cvs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35rem, auto));
    gap: 1rem 2rem;
    grid-template-rows: auto;
    justify-items: center;
    justify-content: center;
  }
  .p-paginator.p-component {
    background-color: c.$fond-page;
  }
}

.annuaire_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .annuaire_filtre {
    width: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 5rem;
    p {
      margin-bottom: 1rem;
    }
    &__label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      p {
        margin-top: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .CVs__container {
    &__cvs {
      grid-template-columns: repeat(1, minmax(0, auto));
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .CVs__container {
    padding-left: 1rem;
    padding-right: 1rem;
    h1 {
      padding-top: 5rem;
      padding-bottom: 2rem;
    }
  }
}
@media screen and (min-width: c.$small) {
  .CVs__container {
    padding: 0 7rem;
  }
}
@media screen and (max-width: 800px) {
  .CVs__container {
    &__cvs {
      grid-template-columns: 1fr;
    }
  }
}
