@use "src/Styles/_vars.scss" as c;

.container_recap {
  width: 80%;
  margin-bottom: 1svh;
  .recap_container {
    margin: 3rem 0 0.5rem 0;
    background-color: white;
    color: c.$noir-rp;
    height: 5rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    justify-content: space-between;
    .h2_title {
      font-size: medium;
      font-weight: 700;
      width: 27rem;
      padding: 0.5rem;
    }
    .h3_loca {
      display: flex;
      align-items: center;
      font-size: medium;
      svg {
        margin-right: 0.3rem;
      }
    }
    .h3_contrat {
      display: flex;
      align-items: center;
      font-size: medium;
      svg {
        margin-right: 0.5rem;
      }
    }
    .divider {
      border-top: unset;
      border-right: 2px solid darkgrey;
      margin: unset;
      width: unset;
    }
    .number_view {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: -4rem !important;
      .number {
        font-weight: 700;
        font-size: x-large;
        margin-bottom: unset;
      }
      .vues {
        font-weight: 400;
        font-size: small;
        text-align: center;
      }
    }
    .number_candidat {
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: c.$vert-recruteur;
      margin-left: -4rem !important;
      .number_can {
        font-weight: 700;
        font-size: x-large;
        margin-bottom: unset;
      }
      .candidatures {
        font-weight: 400;
        font-size: small;
        text-align: center;
      }
    }
  }
  .btn_candidatures {
    color: white;
    background-color: #5eb6a1;
    float: right;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-weight: 600;
  }
  .btn_candidatures:hover {
    color: c.$vert-recruteur;
    background-color: transparent;
    transition: 0.5s;
    border: 1px solid c.$vert-recruteur;
  }
}

@media screen and (min-width: 580px) and (max-width: 1300px) {
  .container_recap {
    width: 42%;
    .recap_container {
      justify-content: unset;
      flex-direction: column;
      height: 15.5rem;
      padding-left: unset;
      padding-top: 1rem;
      .h2_title {
        text-align: center;
        margin-bottom: 0.8rem;
      }
      .divider {
        border: unset;
      }
      .number_view {
        margin-right: unset !important;
      }
      .number_candidat {
        margin-left: unset !important;
        padding-right: unset;
        margin-top: -1rem !important;
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .container_recap {
    width: 75%;
    .recap_container {
      justify-content: unset;
      flex-direction: column;
      height: 15.5rem;
      padding-left: unset;
      padding-top: 1rem;
      .h2_title {
        text-align: center;
        margin-bottom: 0.8rem;
      }
      .divider {
        border: unset;
      }
      .number_view {
        margin-right: unset !important;
      }
      .number_candidat {
        margin-left: unset !important;
        padding-right: unset;
        margin-top: -1rem !important;
      }
    }
  }
}
