@use "src/Styles/_vars.scss" as c;

.buttonsBarConnected {
  text-align: center;
  margin-top: 7rem;
  background-color: c.$fond-boutons-accueil;
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 8rem;
  width: 100%;
  position: relative;

  .buttons {
    padding-bottom: 0rem;
    position: absolute;
    top: -2.5rem;
  }

  .bouton-visible {
    display: inline-block;
    width: 25%;
    font-family: c.$font-sous-menu;
  }

  .bouton-cache-mobile {
    display: inline-block;
    width: 25%;
    font-family: c.$font-sous-menu;
    margin-bottom: 1rem;
  }

  .bouton-visible-mobile {
    display: inline-block;
    width: 50%;
    font-family: c.$font-sous-menu;
    p {
      margin-bottom: 0;
    }
  }

  // modifications d'affichage en passant sur mobile
  @media screen and (max-width: c.$small) {
    .buttons {
      display: flex;
      justify-content: center;
    }
    .bouton-cache-mobile {
      display: none;
    }
    .bouton-visible {
      display: none;
    }
    .barre-boutons .bouton-visible-mobile {
      display: inline-block;
    }
  }
  @media screen and (min-width: c.$small) {
    .barre-boutons .bouton-cache-mobile {
      display: inline-block;
    }
    .bouton-visible-mobile {
      display: none;
    }
  }
  @media screen and (max-width: c.$medium) {
    margin-top: 0;
    .buttons {
      // display: flex;
      justify-content: center;
      top: 0;
      position: unset;
    }
  }
}
