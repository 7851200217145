@use "src/Styles/_vars.scss" as c;

.offre_detail_container {
  background-color: c.$fond-page;

  &__title {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;
    align-items: center;
    flex-wrap: wrap;
    h1 {
      padding-bottom: 2rem;
      color: c.$bleu-paris1;
      font-family: c.$font-gros-titres;
      font-size: xx-large !important;
    }
  }
  &__offre {
    background-color: c.$blanc;
    border-radius: 1rem;
    margin: auto;
    display: flex;
    flex-direction: column;

    &__header {
      background-color: c.$blanc;
      width: 100%;
      overflow: hidden;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      display: flex;
      img {
        max-height: 30rem;
        max-width: 70%;
        margin: 2rem auto;
      }
    }
    &__top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__logo {
        padding-left: 1vh;
        padding-top: 2vh;
        background-color: white;
        img {
          width: 6rem;
          border-radius: 20%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      &__buttons {
        padding: 0.5rem 0.5rem 0 0;
        button {
          background: c.$blanc;
          color: c.$bleu-paris1;
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    &__description {
      padding: 1.5rem 1.5rem 2rem;
      background-color: white;
      &__text {
        padding: 0 1rem 0.5rem 1rem;
        text-justify: inter-word;
      }
      &__titleWithMarge,
      &__title {
        font-family: c.$font-sous-menu !important;
        font-size: 1rem !important;
        color: c.$bleu-paris1;
        margin: 1rem 0;

        & span {
          font-family: c.$font-global !important;
          color: #212529;
          font-weight: unset;
        }

        &--main {
          font-family: c.$font-sous-menu !important;
          color: c.$bleu-paris1;
          font-size: 1.2rem !important;
          font-weight: bold;
          text-transform: uppercase !important;
          margin-bottom: 0.5rem;

          &__offre {
            font-family: c.$font-global !important;
          }
        }
      }
      &__subtitle {
        font-family: c.$font-sous-menu !important;
        font-size: 0.85rem !important;
        color: c.$bleu-paris1;
        span {
          font-family: c.$font-global !important;
          color: #212529;
          font-weight: unset;
        }
      }

      &__titleWithMarge {
        margin: 2rem 0 1rem;
      }

      &__bottom {
        padding: 0 1.5rem 3.5rem;
        display: flex;
        background-color: white;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

        &--left {
          max-width: 50%;
          margin-right: 20%;
          p {
            overflow-wrap: break-word;
          }
        }

        &--right {
          & svg {
            cursor: unset;
          }
        }
      }

      &__infoEntreprise {
        margin-bottom: 2rem;
        &__list {
          display: flex;
          max-width: 60%;
          flex-wrap: wrap;
          justify-content: space-between;
          &__item {
            color: c.$gris-picto;
            display: flex;
            align-items: center;
            & svg {
              margin-right: 0.5rem;

              &:hover {
                // cursor: ;
                cursor: unset;
              }
            }
          }
        }
      }
    }
  }
  &__boutons {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 0 0;
    button {
      margin-right: 2rem;
    }
  }
}

.description__postulate {
  display: flex;
  flex-direction: column;
  .message_recruteur {
    margin-top: 1rem;
    font-family: "font-sous-menu" !important;
    font-size: 1rem !important;
    color: c.$bleu-paris1;
  }
  .text-area-pop-in {
    margin-bottom: 1rem;
  }
  .checkbox_RP,
  .checkbox_Own {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    span {
      font-size: medium !important;
      color: c.$bleu-paris1;
    }
  }
  .download {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    align-items: center;
    .upload_icone {
      width: 22px;
      height: 22px;
      color: c.$bleu-paris1;
    }
    .download_CV {
      margin-left: 0.5rem;
    }
  }

  .postulate_buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .btn-bleu {
      margin-right: 1rem;
    }
  }
}

.modal_contact_candidature_rapide {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  a {
    padding-bottom: 1rem;
    p {
      font-family: "font-sous-menu" !important;
      font-size: 1rem !important;
      color: c.$bleu-paris1;
    }
    span {
      &:hover {
        color: c.$bleu-secondaire;
      }
    }
  }
}
// modifications d'affichage en passant sur tablette
@media screen and (max-width: c.$medium) {
  .offre_detail_container {
    padding: 0 3rem 3rem;
    &__offre {
      max-width: 100%;
      &__top__buttons {
        .groupe_menu.desktop {
          display: block;
        }
      }
    }
  }
}
@media screen and (max-width: c.$tiny) {
  .offre_detail_container {
    padding: 0 1rem 3rem;

    &__title {
      margin-bottom: 1rem;
    }
  }
}
