@use 'src/Styles/_vars.scss' as c;

.error-boundary {
  &__title {
    // large font size (!important needed to override the default)
    font-size: 2.5rem !important;
    margin-bottom: 3svh;
  }
  &__message {
    font-size: large !important;
  }
  &__details {
    // Error stack, so we want to make it smaller, and less important
    font-size: medium !important;
    color: #666;
    // Italics
    font-style: italic;
  }
}