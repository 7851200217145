@use "src/Styles/_vars.scss" as c;

.modal.modal-login {
  width: 50% !important;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__field {
    // width: 130%;
    padding-top: 3vh;
    input {
      border: 0.5px solid c.$bleu-paris1 !important;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 600;
      color: c.$bleu-paris1;
      padding-bottom: 1rem;
    }
    &__error {
      color: red;
      font-size: 0.75rem;
      text-align: center;
      padding-top: 1rem;
    }
    &-password {
      display: unset !important;
    }
  }
  &__button {
    padding-top: 5vh;
    .btn-bleu .loaderContainer {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .loaderContainer__loader {
        width: 2rem;
        height: 2rem;
      }
      .loaderContainer__loader::after {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &__forgot__password {
    cursor: pointer;
    text-decoration: underline;
    margin-top: 1rem;
    display: flex;
  }
}
