@use "src/Styles/_vars.scss" as c;

.userConnected {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__unifLogo {
    width: 6rem;
    // margin-right: 2rem;
  }
  &__icons {
    display: flex;
    padding-top: 0.55rem;
    margin-right: -1rem;
    color: c.$noir-rp;
    // width: 100%;
    &__icon {
      font-size: 1.5rem !important;
      margin: 0 1.5rem;
      padding-top: 0.2rem;
    }
  }
  &__picture {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover;
  }
  &__right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: 2rem;

    &__image_button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
    }
  }
  button {
    position: relative;
    color: c.$bleu-commun;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 20%;
    border: none;
    background-color: transparent;
    font-weight: bold;
    padding: 0.5rem 0.7rem;
    border-radius: 5rem;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      color: c.$blanc;
      background-color: c.$bleu-commun;
    }
  }

  .connexionInterfaceConnected {
    position: absolute;
    z-index: 9999;
    width: 12rem;
    background-color: c.$blanc;
    color: c.$bleu-commun;
    margin-left: 4rem;
    padding: 0.5rem;
    border-radius: 1rem;
    top: 3rem;
    right: 0;

    li,
    a {
      padding: 0.5rem;
      color: c.$bleu-commun;

      &:hover {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}

.image_button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
