@use "src/Styles/_vars.scss" as c;

#header {
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 20rem;
    background-blend-mode: multiply;
    &__connected {
      width: 100%;
      // background-image: linear-gradient(lightgrey, grey),
      //   url("../../assets/fond-accueil.webp");
    }
    &__notConnected {
      width: 100%;
      // background-image: linear-gradient(#d8bfd8, #941c56),
      //   url("../../assets/fond-accueil.webp");
    }
  }
}

@media screen and (max-width: c.$medium) {
  #header {
    .menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
      height: fit-content;
      min-height: unset;
      &__connected {
        background-image: none;
      }
      &__notConnected {
        background-color: c.$noir-rp;
        background-image: none;
        background-blend-mode: multiply;
      }
    }
  }
}
