@use "src/Styles/_vars.scss" as c;
.cardHoverEvenement {
  transition-duration: 0.2s;
  box-shadow: 2px 3px 6px lightgrey;
  background-color: white;
  margin-bottom: 2rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}
.evenement_container {
  border-radius: 0.33rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__top {
    width: 100%;
    position: relative;
    &__categorie {
      font-family: c.$font-global;
      font-size: c.$font-size-normal;
      font-weight: 700;
      color: c.$gris-picto;
      position: relative;
      display: flex;
      justify-content: flex-end;
      .etiquette {
        position: absolute;
        margin-top: 1rem;
        margin-right: 1rem;
        padding: 0.2rem 2rem;
        background-color: c.$fond-page-clair;
        color: grey;
        border-radius: 5px;
        z-index: 1000;
      }
    }
  }
  &__image {
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0.33rem 0.33rem 0 0;
    .image__bg {
      position: absolute;
      width: 110%;
      height: 110%;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(4px);
      opacity: 0.6;
    }
    img {
      z-index: 900;
      max-width: 100%;
      max-height: 12rem;
      object-fit: cover;
      font-weight: 700;
      border-top-left-radius: 0.33rem;
      border-top-right-radius: 0.33rem;
    }
  }
  &__corps {
    padding: 1rem 1rem 0rem;
    height: 15rem;
    &__debutdate {
      font-family: c.$font-global;
      font-size: 0.9rem;
      color: c.$gris-picto;
      padding: 0.2rem 0 0 1rem;
    }
    &__findate {
      font-family: c.$font-global;
      font-size: 0.9rem;
      color: c.$gris-picto;
      padding: 0 1rem 0 1rem;
    }
    &__title {
      font-family: c.$font-sous-menu;
      color: c.$noir-rp;
      margin: 2rem 1rem 1rem 1rem;
      line-height: 20px;
      font-size: 1.25rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &__inscription {
      font-family: c.$font-global;
      font-size: 0.95rem;
      font-weight: 700;
      color: c.$vert-icon;
      padding: 0.5rem 1rem 0 1rem;
    }
    &__chapeau {
      font-family: c.$font-global;
      font-size: 1rem;
      margin: 0 3rem 3rem 1rem;
      line-height: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 2rem 1rem;
    &__lien {
      display: flex;
      font-family: c.$font-global;
      font-weight: 700;
      font-size: 1rem;
      color: c.$bleu-secondaire;
      padding: 1rem 1rem 0 1rem;
    }
    &__logo {
      padding: 1rem 1rem 0 1rem;
      img {
        width: 8rem;
        height: auto;
      }
    }
  }
}
// evenement container pour la partie groupe
.evenement_container {
  &__corps {
    &__debutdate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-point {
        height: 1rem;
        .btn-blanc {
          border: none;
          background-color: transparent;
          color: c.$bleu-paris1;
          padding: 0;
          margin: 0;
          height: 1rem;

          &:hover {
            color: c.$bleu-paris1;
            background-color: white;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 315px) and (max-width: 380px) {
  .evenement_container {
    &__bottom {
      margin-top: 1rem;
      &__lien {
        font-size: 0.6rem;
      }
    }
  }
}

@media screen and (max-width: 315px) {
  .evenement_container {
    height: 54rem;
    &__top {
      &__categorie {
        float: unset;
        text-align: center;
      }
    }

    &__bottom {
      flex-direction: column;
      padding: unset;
      margin-top: 23.5rem;

      &__lien {
        margin-bottom: 1rem;
      }
    }
  }
}
