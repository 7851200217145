@use "src/Styles/_vars.scss" as c;

.universite {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  img {
    margin-top: 0.5rem;
    margin-right: 2rem;
    box-shadow: 2px 3px 6px lightgray;
    width: 180px;
    height: 100px;
  }
  padding: 2rem 0 2rem 0;
  &__container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    &__buttons {
      display: flex;
      justify-content: flex-start;
      .btn-blanc,
      .btn-bleu {
        margin: 0;
      }
      .btn-bleu {
        margin-right: 1rem;
      }
    }
  }
}
.universite.mobile {
  display: none;
  .p-accordion.p-component {
    width: 100%;
  }
}
@media screen and (max-width: c.$small) {
  .universite {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    img {
      margin-bottom: 0.5rem;
    }
    &__container {
      &__buttons {
        justify-content: center;
      }
    }
  }
}
