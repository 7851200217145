@use "src/Styles/_vars.scss" as c;

.p-toast {
  z-index: 10000;
}

.profil_show {
  background-color: c.$fond-page;
  // taille du menu et alignement en hauteur
  #header .menu__connected {
    height: 10rem;
    .profilPage__headerProfilBar {
      margin: 4rem 0;
    }
  }
  &__container__article.desktop {
    display: none;
  }
  // partie basse de la page
  &__container {
    padding: 16rem 7rem 2rem 7rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &__column {
      display: flex;
      justify-content: space-between;
      &__article {
        width: 100%;
      }
    }
    &__article {
      width: 30%;
      min-width: 300px;
      height: fit-content;
      background-color: #ffffff;
      padding: 1rem;
      border-radius: 0.33rem;
      margin-bottom: 2rem;
      box-shadow: 0px 2px 0.2px lightgrey;
      &__container {
        img {
          height: 4rem;
        }
        h2 {
          font-family: c.$font-global;
          margin-bottom: 0.5rem;
          span {
            color: red;
          }
        }
        h1 {
          font-family: c.$font-sous-menu;
          margin-bottom: 1rem;
        }
        .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
          margin-top: 1rem;
        }
        // boite pour une compétence
        &__experience {
          h4 {
            font-size: large;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: small;
            margin-bottom: 0.5rem;
            font-style: italic;
            span {
              color: c.$bleu-secondaire;
            }
          }
          h5,
          p {
            margin-bottom: 0;
          }
          // ligne de compétence
          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            font-size: small;
            h5 {
              margin-right: 0.5rem;
              font-size: medium;
            }
          }
        }
        // boite compétences finie
        &__competences {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: wrap;
          // "bouton" compétence
          .competence {
            border-radius: 2rem;
            border: 1px solid c.$bleu-paris1;
            width: fit-content;
            display: flex;
            align-items: center;
            height: fit-content;
            min-height: 2.5rem;
            margin: 0 2rem 0.5rem 0;
            padding: 0.5rem;
            // texte bouton compétence
            h4 {
              padding: 0 1rem;
              text-transform: uppercase;
              font-family: c.$font-global;
              font-weight: 700;
              margin-bottom: 0;
              color: c.$bleu-paris1;
              font-size: small;
            }
          }
        }
      }
      &__title {
        h1 {
          padding: 0.5rem 0 1rem 0;
          font-family: c.$font-gros-titres;
          font-size: xx-large !important;
          color: c.$bleu-paris1;
        }
      }
    }
  }
  .btn-bleu {
    display: flex;
    align-self: center;
    margin-bottom: 4rem;
    margin-left: 7rem;
  }
}

//modifications d'affichage en passant sur tablette
@media screen and (max-width: c.$medium) {
  .profil_show {
    #header .menu__connected {
      height: fit-content;
      background-color: c.$fond-page;
    }
    &__container {
      padding: 0 7rem 2rem 7rem;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .profil_show {
    #header .menu__connected {
      height: fit-content;
      .profilPage__headerProfilBar {
        margin: 1rem 0;
      }
    }
    &__container {
      margin-top: 40svh;
      &__column {
        flex-direction: column;
      }
    }
    &__container__article.mobile {
      display: none;
    }
    &__container__article.desktop {
      display: unset;
    }
    background-color: c.$fond-page;
    &__container {
      padding: 0 1rem;
      h1 {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      &__column {
        width: 100%;
      }
      &__article {
        width: 100%;
      }
    }
  }
}
