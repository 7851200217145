@use "src/Styles/_vars.scss" as c;

.offre {
  background-color: white;
  border-radius: 0.33rem;
  font-family: c.$font-global;
  display: flex;
  flex-direction: column;

  &__categorie {
    padding: 0 1.5rem 0 1.5rem;
    font-weight: 700;
    span {
      display: flex;
      align-items: center;
      width: fit-content;
      position: relative;
      svg {
        margin-right: 0.5rem;
      }
      img {
        margin-right: 0.5rem;
      }
    }
    &__entreprise {
      padding: 0.25rem 0.5rem;
      border-radius: 0.33rem;
      background-color: c.$fond-entreprise-partenaire;
      color: c.$texte-entreprise-partenaire;
      margin-top: 1rem;
    }
    &__entreprise.contrat_pro {
      margin-top: 1rem;
    }
    &__alumni {
      padding: 0.25rem 0.5rem;
      border-radius: 0.33rem;
      background-color: c.$fond-alumni;
      color: c.$texte-alumni;
      margin-top: 1rem;
    }
    &__nouvelle {
      padding: 0.25rem 0.5rem;
      border-radius: 0.33rem;
      margin-top: 1rem;
      background-color: c.$fond-nouvelle-offre;
      color: c.$texte-nouvelle-offre;
    }
  }

  &__container {
    padding: 1rem 1rem 0 1rem;
    height: fit-content;
    &__description {
      display: flex;
      flex-direction: column;
      &__title {
        padding: 2rem 0rem 0rem 1.5rem;
        font-family: c.$font-sous-menu;
        --webkit-line-clamp: 2;
        --webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        color: c.$noir-rp;
        font-size: c.$font-size-x-normal;
        height: 6rem;
      }
      &__statut {
        padding: 0 1.5rem 0 1.5rem;
        min-height: 2rem;
        color: c.$gris-picto;
        span {
          margin-left: 1rem;
        }
      }
      &__handicap {
        &__icon {
          padding: 0 1.5rem 0 1.5rem;
          min-height: 2rem;
          color: c.$gris-picto;
          span {
            margin-left: 1rem;
          }
        }
      }
    }
    .offre_envoyee {
      color: red;
    }
  }

  &__image {
    position: relative;
    // height: 12rem;
    &__header {
      height: 12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 0.33rem 0.33rem 0 0;
      .image__bg {
        position: absolute;
        width: 110%;
        height: 110%;
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(4px);
        opacity: 0.6;
      }
      img {
        z-index: 900;
        max-width: 100%;
        max-height: 12rem;
        object-fit: cover;
        border-top-left-radius: 0.33rem;
        border-top-right-radius: 0.33rem;
      }
    }
    &__logo {
      img {
        z-index: 1000;
        left: 8%;
        position: absolute;
        border-radius: 50%;
        width: 7rem;
        height: 7rem;
        border: 2px solid lightgrey;
        bottom: -22%;
      }
    }
    &__logo_univ {
      z-index: 1000;
      display: flex;
      left: 8%;
      position: absolute;
      border-radius: 50%;
      border: 2px solid lightgrey;
      bottom: -22%;
      background-color: white;
      width: 7rem;
      height: 7rem;
      img {
        align-self: center;
        width: 7rem;
        height: 4rem;
      }
    }
  }
  &__date {
    background-color: c.$fond-page-clair;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.33rem;
    color: c.$bleu-secondaire;
    padding: 1rem 2.5rem;
    margin-top: 2.5rem;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
    .loaderContainer {
      width: fit-content;
      padding: 0;
      &__loader,
      &__loader::after {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }
}
.posted_by_partner,
.posted_by_alumni,
.posted_new {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0rem 0 1.5rem 1.5rem;
  padding: 0.3rem 0.5rem;
  width: fit-content;
  border-radius: 0.33rem;
  span {
    margin-left: 1rem;
  }
}
.posted_by_alumni {
  background-color: c.$fond-alumni;
  color: c.$texte-alumni;
}
.posted_by_partner {
  background-color: c.$fond-entreprise-partenaire;
  color: c.$texte-entreprise-partenaire;
  img {
    color: c.$texte-entreprise-partenaire;
  }
}
.posted_new {
  background-color: c.$fond-nouvelle-offre;
  color: c.$texte-nouvelle-offre;
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: 440px) {
  .offre {
    width: 100% !important;
    height: fit-content !important;
    &__container {
      padding: 0.5rem 1rem 0 1rem;
    }
    &__image {
      &__logo {
        img {
          top: 5rem;
        }
      }
    }
    &__date {
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 1400px) {
  .offre {
    &__container {
      &__description {
        .offre__categorie {
          span {
            margin-top: 0;
          }
        }
      }
    }
  }
}
