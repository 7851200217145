@use "src/Styles/_vars.scss" as c;

.chiffres {
  background-color: c.$noir-rp;
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  &__column {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: fit-content;
    // .chiffre {
    //   width: 50%;
    // }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .chiffres {
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
    &__column {
      width: 100%;
      .chiffre {
        width: 50%;
        padding-bottom: 0;
      }
    }
  }
}
