@use "src/Styles/_vars.scss" as c;

.CVs__container {
  &__titlePanier {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    &__title {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      padding-top: 4rem;
    }

    &__searchAndCreate {
      display: flex;
      width: 50%;
      align-items: baseline;
      justify-content: flex-end;
      .cv_paniers_searchBar {
        max-width: 15rem;
        input {
          max-width: 15rem;
        }
      }

      button.btn-blanc {
        height: 2.5rem;
        min-width: 15rem !important;
      }
    }
  }

  .cv_paniers_searchBar {
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
    margin-right: 2rem;
    &__icon {
      position: absolute;
      color: c.$gris-picto;
      font-size: 1.5rem;
      top: 25%;
      left: 2%;
    }
    & input {
      border-radius: 5rem;
      padding: 0.75rem 0.75rem 0.75rem 2.5rem;
      border: none;
      font-size: 1rem;
      // modification du placeholder pour tous les navigateurs
      &::-webkit-input-placeholder {
        /* Edge */
        color: c.$gris-picto;
        font-family: c.$font-global;
      }
      .cv_paniers_searchBar {
        position: relative;
        width: 60%;
        margin-bottom: 1rem;
        text-align: center;
        &__icon {
          position: relative;
          color: c.$gris-picto;
          font-size: 1.5rem;
          top: 2rem;
          display: flex;
          margin-left: 18%;
        }
        input {
          border-radius: 5rem;
          padding: 1rem 1rem 1rem 3rem;
          border: none;
          font-size: 1rem;
          width: 70%;
          height: 2.5rem;
          // modification du placeholder pour tous les navigateurs
          &::-webkit-input-placeholder {
            /* Edge */
            color: c.$gris-picto;
            font-family: c.$font-global;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: c.$gris-picto;
            font-family: c.$font-global;
          }

          &::placeholder {
            color: c.$gris-picto;
            font-family: c.$font-global;
          }
        }
      }
    }
  }

  &__paniers {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, auto));
    gap: 1rem;
    grid-template-rows: auto;
    justify-items: center;
  }

  &__bouton {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: c.$large) {
  .CVs__container__paniers {
    grid-template-columns: repeat(1, minmax(0, 50%));
    justify-content: center;
  }
}

@media screen and (max-width: c.$normal) {
  .CVs__container {
    &__titlePanier {
      flex-direction: column;
      padding-top: 5rem;
      padding-bottom: 3rem;
      h1 {
        padding: 0;
      }
      &__searchAndCreate {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        &__createBtn {
          min-width: 15rem !important;
        }
      }
    }
  }
}
