@use "src/Styles/_vars.scss" as c;

.entreprises_cards {
  max-width: 25rem;
  min-width: 20rem;
  height: 20rem;
  background-color: c.$blanc;
  margin: 2rem 2rem;
  box-shadow: 2px 3px 6px lightgrey;
  transition: 0.4s;
  &__top {
    position: relative;
    img {
      width: 100%;
      height: 9rem;
    }
    &__logo {
      img {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 6rem;
        left: 4%;
        border-radius: 50%;
        border: 2px solid lightgrey;
      }
    }
  }
  &__corps {
    padding: 2.8rem 1.5rem 0 1.5rem;
    h3 {
      font-family: c.$font-sous-menu;
      font-size: c.$font-size-x-normal;
      line-height: 20px;
      width: 22rem;
      height: 1.5rem;
      margin-bottom: 0.3rem;
    }
    p {
      margin-bottom: unset;
      i {
        margin-right: 0.5rem;
      }
      svg {
        margin-right: 0.5rem;
      }
    }
  }
  &__bottom {
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    .btn_card {
      width: 11rem;
      height: 1.4rem;
      font-family: c.$font-sous-menu;
      font-size: x-small;
      color: c.$blanc;
      border: unset;
      background-color: c.$bleu-secondaire;
      border-radius: 0.2rem;
      transition: 0.4s;
    }
    svg {
      margin-right: 0.2rem;
      margin-bottom: 0.15rem;
      color: c.$blanc;
    }
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 10px 0px darkgrey;
  }
}

@media screen and (max-width: c.$tiny) {
  .entreprises_cards {
    width: 90%;
    min-width: unset;
    max-width: none;
    height: fit-content;
    // padding: 5% 0;
    margin: 5% 2rem;
    &__top {
      img {
        width: 100%;
      }
      &__logo {
        img {
          width: 5rem;
        }
      }
    }
    &__bottom {
      padding-bottom: 0.5rem;
    }
  }
}
