.login__form.reset__password {
  .send_new_mail {
    cursor: pointer;
    text-decoration: underline;
    text-align: right;
    font-size: 0.8rem;
    padding: 0.5rem;
    margin-top: 1rem;
    display: flex;
    align-self: flex-end;
  }
  .forgot__password__information {
    margin-top: 1rem;
  }
  .loaderContainer {
    padding: 0;
    background: transparent;
  }
}
