@use "src/Styles/_vars.scss" as c;

.card_container {
  background-color: white;
  width: 40%;
  height: 9rem;
  color: c.$noir-rp;
  box-shadow: 2px 3px 6px lightgrey;
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2.5rem;
    height: 3rem;
    .h2_refe {
      margin-bottom: unset;
      font-size: small;
      font-weight: 700;
      text-transform: uppercase;
      width: 26rem;
    }
    .h3_time {
      color: lightgrey;
      width: 7rem;
    }
    .time-trash {
      display: flex;
      justify-content: space-between;
      width: 7rem;
      svg {
        color: c.$bleu-secondaire;
      }
    }
  }
  &__body {
    display: flex;
    padding-left: 2.5rem;
    .imgA {
      border: 2px solid c.$noir-rp;
      border-radius: 50%;
      width: 4.5rem;
    }
    .details {
      margin-left: 1.5rem;
      margin-top: 0.5rem;
      .h2_name {
        margin-bottom: 0.2rem;
        font-size: small;
        font-weight: 700;
        display: flex;
        .imgB {
          margin-left: 0.3rem;
          margin-bottom: 0.1rem;
        }
        .crown {
          margin-left: 0.5rem;
        }
      }
    }
  }
  &__bottom {
    padding-left: 8.5rem;
    font-size: small;
    display: flex;
    margin-top: -1.2rem;
    .show_candidature {
      margin-right: 3.5rem;
      color: c.$vert-groupe !important;
      font-weight: 500;
    }
    .download_cv {
      font-weight: 500;
      color: c.$bleu-secondaire !important;
      .fa {
        margin-right: 0.3rem;
      }
    }
  }
}
.confirm_delete_candidature {
  .p-button {
    color: c.$bleu-paris1 !important;
    box-shadow: none !important;
  }
}

@media screen and (min-width: 580px) and (max-width: 1500px) {
  .card_container {
    width: 65%;
  }
}

// @media screen and (min-width: 400px) and (max-width: 580px) {
//   .card_container {
//     width: 90%;
//   }
// }

@media screen and (max-width: 580px) {
  .card_container {
    width: 100%;
    &__bottom {
      padding: 5%;
    }
  }
}
