@use "src/Styles/_vars.scss" as c;

.footer {
  background-color: c.$noir-rp;
  padding-top: 1rem;
  &__column {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0 0.5rem;
  }
  &__top {
    align-items: baseline;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    // padding-bottom: 1rem;
    // min-height: 15rem;
    &__text {
      display: flex;
      justify-content: center;
      // flex-wrap: wrap;
      align-items: flex-start;
    }
    &__li {
      margin: 0.5rem 1rem 1.5rem 0.5rem;
      li:hover {
        text-decoration: underline;
      }
      li {
        color: white;
        font-family: c.$font-global;
        margin-bottom: 0.5rem;
        font-size: small;
      }
      img {
        height: 5rem;
        min-height: 7rem;
      }

      &__socialsLinks {
        width: 80%;
        margin: auto;
        margin-top: -0.8rem;
        display: flex;
        color: c.$blanc;
        font-size: 1.5rem;
        justify-content: space-between;
      }
    }

    .trait_dessus {
      border-top: 2px solid white;
      width: 30px;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    h6 {
      color: white;
      font-family: c.$font-sous-menu;
      margin-bottom: 0rem;
      position: relative;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem 5rem;
    // flex-wrap: wrap;
    align-items: center;
    background-color: white;
    color: c.$noir-rp;
    // min-height: 7rem;
    h6 {
      margin-right: 1rem;
      font-weight: 300;
      font-family: c.$font-global;
      font-size: small;
      margin-bottom: 0rem;
    }
    &__left {
      color: c.$noir-rp;
      font-family: c.$font-global;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 0 1rem;
    }
    &__right {
      margin: 1rem 0;
    }
    .footer_rp {
      width: 6.5rem;
      padding-bottom: 1rem;
    }
    .footer_mini {
      width: 12rem;
    }
    .footer_pep,
    .footer_unif {
      width: 8rem;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .footer {
    &__top {
      &__text {
      }
    }
    &__column {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &__bottom {
      flex-direction: column;
      row-gap: 2svh;
    }
  }
}
@media screen and (max-width: c.$tiny) {
  .footer {
    &__top {
      &__text {
      }
    }
    &__column {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
  }
}
@media screen and (max-width: 440px) {
  .footer {
    &__top {
      &__text {
        flex-direction: column;
      }
    }
  }
}
