@use "src/Styles/_vars.scss" as c;

.profilBarShow {
  background-color: white;
  border-radius: 0.33rem;
  height: 14rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin: 0 20%;
  padding: 0 1rem 0 0;
  h2 {
    font-family: c.$font-gros-titres;
    margin-bottom: 0;
  }
  h3 {
    font-family: c.$font-sous-menu;
    font-size: 1.2rem;
  }
  p {
    margin-bottom: 1.5rem;
  }

  .p-divider.p-divider-vertical {
    min-height: 80%;
    margin: 0 0.5rem;
  }
  .p-divider.p-divider-horizontal {
    display: none;
  }
  &__image {
    width: 8rem;
    height: 8rem;
    margin: 0 2rem;
    img {
      width: 100%;
      border-radius: 50%;
      border: 2px solid c.$bleu-paris1;
      margin-bottom: 0.8rem;
      object-fit: cover;
      height: 100%;
    }
  }
  // partie description
  &__middle {
    margin: 0 1rem;
    width: 70%;
    &__parrain {
      background-color: c.$fond-parrain;
      color: c.$texte-parrain;
      border-radius: 0.33rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      width: 8.5rem;
      p {
        text-transform: uppercase;
        margin: 0.5rem 0.5rem 0.5rem 0.8rem;
        font-size: x-small !important;
      }
    }
  }
  // partie logos
}
.profilBarShow__right {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 0.33rem;
  position: absolute;
  top: 34rem;
  margin-top: 1rem;
  &__item {
    margin-left: 1rem;
    font-size: medium;
    svg {
      margin-right: 0.5rem;
    }

    label {
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-left: 0;
      img {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    p {
      display: flex;
      justify-content: flex-start;
    }
  }
  &__logos {
    img {
      margin-right: 0.5rem;
    }
  }
  // style du texte
  h2 {
    font-family: c.$font-gros-titres;
    text-transform: uppercase;
    font-size: x-large;
  }
  h3 {
    font-family: c.$font-global;
    font-weight: 700;
    font-size: medium;
  }
  p {
    font-family: c.$font-global;
    font-size: small;
    margin-bottom: 0.5rem;
    a:hover {
      color: c.$bleu-secondaire !important;
    }
  }
}
.btn_ProfilBarShow {
  display: flex;
  justify-content: flex-end;
  margin: 8rem 7rem 0 0;
}

@media screen and (max-width: c.$medium) {
  .profilBarShow {
    width: 80%;
    margin: 0 10%;
    &__right {
      width: 80%;
      margin: 2rem 10%;
    }
  }
}
@media screen and (max-width: c.$small) {
  .profilBarShow {
    flex-direction: column;
    margin: 0 1rem;
    height: fit-content;
    width: fit-content;
    box-shadow: 0px 2px 0.2px lightgray;
    .p-divider.p-divider-vertical {
      display: none;
    }
    .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
      display: unset;
      margin-top: 0;
      justify-content: center;
      width: 80%;
    }
    &__middle {
      width: fit-content;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        text-align: center;
      }
    }
    &__right {
      width: fit-content;
      padding-bottom: 1rem;
      margin: 2rem 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      &__item {
        margin-left: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        p {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
