@use "src/Styles/_vars.scss" as c;

.info_entreprise {
  #header {
    .menu {
      height: 30svh;
    }
  }
  &__container {
    background-color: c.$fond-page;
    padding: 4rem 7rem 0rem;
    display: flex;
    flex-direction: column;
    form {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-bottom: 5rem;
      .block {
        display: flex;
        flex-direction: row;
        margin: 1.5rem 0;
        .field {
          width: 100%;
          margin-right: 2rem;
          display: flex;
          flex-direction: column;
          .checkboxes {
            display: flex;
            flex-direction: row;
            .checkbox {
              margin-right: 2rem;
              .p-checkbox {
                margin-right: 0.25rem;
              }
            }
          }
          .p-dropdown {
            width: 15rem;
          }
          h2 {
            color: #00326e;
            font-family: "font-sous-menu";
            font-size: medium;
            margin-bottom: 0.7rem;
          }
          input {
            max-width: 20rem;
          }
          textarea {
            width: 100%;
          }
          .p-error {
            font-size: 1rem;
            margin-left: 0.5rem;
            margin-top: 0.5rem;
          }
        }
        .entreprise_description {
          display: block;
        }
        .entreprise_img {
          img {
            max-width: 10svw;
            max-height: 10svw;
          }
        }
        .entreprise_upload {
          span {
            font-size: small;
          }
        }
      }
    }
    &-submit {
      align-self: center;
      margin-top: 3svh;
    }
  }
}

// Media query for mobile devices
@media (max-width: 768px) {
  .info_entreprise {
    &__container {
      form {
        .block {
          flex-direction: column;
          row-gap: 3svh;
        }
      }
    }
  }
}
