@use "src/Styles/_vars.scss" as c;

.actualite_detail_container {
  background-color: c.$fond-page;
  &-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25vh;
    color: c.$bleu-paris1;
  }
  &__title {
    padding: 4rem 7rem 2rem;
    font-family: "font-gros-titres";
    color: c.$bleu-paris1;
    font-size: xx-large !important;
    margin-bottom: 1rem;
  }
  #header .menu {
    min-height: 0;
  }
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2rem 7rem;
    &__body {
      background-color: white;
      border-radius: 0.5rem;
      width: 70%;
      box-shadow: 2px 3px 6px lightgray;
      display: flex;
      flex-direction: column;
      &__categorie {
        margin: 1rem 0 0.5rem 0;
        color: c.$gris-picto;
        line-height: 1.5;
        font-weight: bold;
        padding: 0 1rem;
        font-size: medium;
      }
      &__chapo {
        font-weight: bold;
        padding: 0 1rem;
        font-size: medium;
      }
      & h1 {
        padding: 0 1rem;
        font-size: x-large !important;
        color: c.$bleu-paris1;
        font-weight: bold;
      }
      &__texte {
        padding: 1rem;
        &__date {
          color: c.$gris-picto;
          font-family: c.$font-global;
          margin-bottom: 5svh;
          font-size: medium;
          font-weight: bold;
        }
        &__liens {
          color: c.$gris-picto;
          font-family: c.$font-global;
          font-size: medium;
          font-weight: bold;
          &-url {
            color: c.$bleu-secondaire !important;
          }
        }
        &__description {
          .quill {
            .ql-container {
              border: none;
            }
          }
        }
      }
      img {
        margin: 2rem auto;
        max-width: 70%;
        max-height: 20rem;
      }
    }
    &__card {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      .btn-blanc {
        margin: 1rem 0;
      }
      .actualite_container {
        height: unset;
        border-radius: 1rem;
        margin: unset;
        width: 30rem;
        background-color: white;
        &__top {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
        &__bottom {
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }

  &__bottom {
    padding: 2rem 7rem;
    background-color: c.$fond-page;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    &__title {
      h1 {
        font-size: x-large !important;
      }
    }

    &__event {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      flex-direction: row;
    }
    padding-bottom: 4rem;
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .actualite_detail_container {
    &__top,
    &__bottom {
      padding: 2rem 1rem;
    }

    &__top {
      &__body {
        width: 100%;
        margin-bottom: 2rem;
      }
      &__card {
        width: 100%;
        .actualite_container {
          width: 100%;
          &__corps {
            height: fit-content;
          }
          &__bottom {
            margin: unset;
          }
        }
      }
    }
    &__bottom {
      width: 100%;
      &__event {
        width: 100%;
      }
    }
  }
}
// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$medium) {
  .actualite_detail_container {
    &__title {
      padding: 4rem 1rem 2rem;
    }
    &__top {
      &__body {
        width: 100%;
        margin-bottom: 2rem;
      }
      &__card {
        width: 30rem;
        .actualite_container {
          width: 100%;
          &__corps {
            height: fit-content;
          }
          &__bottom {
            margin: unset;
          }
        }
      }
    }
    &__bottom {
      width: 100%;
      &__event {
        width: 100%;
      }
    }
  }
}
