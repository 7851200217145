@use "src/Styles/_vars.scss" as c;

.cv_search_bar {
  padding-bottom: 3rem;
  .p-inputtext {
    border: none;
  }
  .p-input-icon-left > .p-inputtext {
    padding-left: 0.5rem;
  }
  &__title {
    padding: 0.5rem;
    color: c.$blanc;
    font-family: c.$font-gros-titres;
    font-size: x-large !important;
  }
  &__container {
    border-radius: 1rem;

    .p-divider.p-divider-vertical {
      margin: 0 0.5rem;
    }

    &__top {
      display: flex;
      flex-direction: row;
      background-color: white;

      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      &__boxes {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 95%;
        .p-divider-horizontal {
          display: none;
        }
        &__box {
          padding: 0.5rem 0 0.5rem 0.5rem;
          width: 15rem;
          label {
            font-family: c.$font-sous-menu;
            color: c.$bleu-paris1;
            text-transform: uppercase;
            display: block;
          }
          .p-input-icon-left {
            width: 100%;
            display: flex;
            justify-content: flex-start;
          }
          .p-divider.p-component.p-divider-vertical.p-divider-solid.p-divider-center {
            padding: 0;
            margin: 0;
          }
        }
        .box__input {
          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 3rem 0.5rem 0 !important;
            display: flex;
            justify-content: flex-start;
          }
        }
        .box__input.blanc {
          background-color: white !important;
          width: 100%;

          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 3rem 0.5rem 0 !important;
          }
        }
        .p-multiselect.p-component.p-inputwrapper.box__input {
          background-color: white !important;
          max-width: 100%;
          .blanc {
            .p-multiselect-label.p-placeholder {
              margin-left: 0.5rem;
            }
          }
        }

        .p-inputtext.p-component {
          width: 100%;
          background-color: white;
        }
      }
      &__inputSearch {
        background-color: c.$bleu-secondaire;
        display: flex;
        font-size: 2rem;
        color: c.$blanc;
        width: 5%;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 0;
        border: none;
      }
    }
    .box__input {
      border-radius: 2rem;
      background-color: #fafafa;
    }
    &__bottom {
      background-color: c.$fond-page-clair;
      padding: 0.5rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      display: flex;
      position: relative;

      &__boxes {
        &__multiselect {
          padding: 0.5rem 0 0.5rem 0.5rem;
          display: flex;
          align-items: center;
          width: 12rem;
          .p-multiselect-label.p-placeholder {
            margin-left: 0.5rem;
          }
        }
        &__box {
          width: 15rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          label {
            font-family: c.$font-sous-menu;
            color: c.$bleu-paris1;
            display: block;
          }
          .p-input-icon-left {
            width: 100%;
            display: flex;
            justify-content: flex-start;
          }
          .p-divider.p-component.p-divider-vertical.p-divider-solid.p-divider-center {
            padding: 0;
            margin: 0;
          }
        }
        &__checkbox {
          color: c.$gris-picto;
          font-family: c.$font-global;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          margin-right: 1rem;
          width: 14rem;
          p {
            margin-bottom: 0;
            margin-left: 0.5rem;
          }
          label {
            display: none;
          }
          .box_input {
            border: none;
          }
        }
      }

      li {
        width: fit-content;
      }
      .box__input.gris {
        background-color: c.$fond-page-clair !important;
        width: 6rem !important;
        margin-left: 0.5rem;
        .p-inputtext.p-component {
          background-color: c.$fond-page !important;
        }
        .p-placeholder {
          color: c.$gris-picto;
          padding: 0.5rem 3rem 0.5rem 0 !important;
        }
      }
    }
    .p-slider-horizontal,
    .slider-demo .p-inputtext {
      min-width: 3rem;
    }
    .p-slider.p-slider-horizontal {
      margin-left: 1rem;
    }
    .mobile {
      display: none;
    }
  }
  .p-accordion-tab {
    padding-left: 0;
  }
  .p-dropdown {
    background-color: white;
    &.p-focus {
      box-shadow: none !important;
    }
  }
}

.search_bar_refresh__cvs {
  position: absolute;
  right: 0;
  top: 30%;
}

// Entre 0 et 830px
@media screen and (max-width: c.$small) {
  .p-inputtext.p-component.box__input {
    width: 100% !important;
  }
  .p-multiselect.p-component.p-inputwrapper.box__input.blanc {
    width: 100% !important;
  }
  .p-accordion-header-text {
    width: 100%;
    .cv_search_bar__container__top__boxes__box.top_mobile {
      width: 100%;
      .p-inputtext.p-component.box__input {
        width: 100% !important;
      }
    }
  }
  .cv_search_bar__container__bottom {
    &__boxes__checkbox {
      width: 100% !important;
    }
  }
  .cv_search_bar__container__top__inputSearch.desktop {
    width: 100%;
  }

  .p-multiselect.p-component.p-inputwrapper.box__input.blanc {
    width: 100%;
  }
  .p-inputtext.p-component.box__input {
    width: 100% !important;
  }
  .p-inputtext.p-component.box__input {
    width: 100% !important;
  }
}
// Entre 0 et 1200px
@media screen and (max-width: c.$medium) {
  .question {
    width: 90% !important;
  }
  .cv_search_bar {
    &__filtres {
      display: none;
    }
    &__container {
      background-color: transparent;

      &__top {
        flex-direction: column;
        background-color: transparent;
        &__boxes {
          flex-direction: column;
          padding: 0;
          width: 100%;
          margin-top: 1rem;
          &__box {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            text-align: center;
            padding: 0 0 0.5rem 0;
            label {
              display: flex;
              justify-content: flex-start;
            }
            .p-inputtext.p-component.box__input {
              height: 2.5rem;
            }
            .p-input-icon-left.p-inputtext {
              margin-left: -2rem;
            }
          }

          .p-divider-vertical {
            display: none;
          }
        }
        &__inputSearch {
          width: 45%;
          align-items: center;
          justify-content: center;
          border-radius: 2rem;
          padding: 0.5rem;
          display: none;
        }
      }
      &__bottom {
        flex-direction: column;
        background-color: transparent;
        padding: 0.5rem 0 0 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__boxes {
          &__box {
            padding: 0 0 0.5rem 0;
          }
          &__multiselect {
            padding: 0rem 0 0.5rem 0rem;
          }
          &__checkbox {
            color: c.$gris-picto;
            font-family: c.$font-global;
            font-size: 0.9rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 0;
            width: 100%;
            p {
              display: none;
            }
            label {
              display: flex;
              justify-content: flex-start;
              color: c.$bleu-paris1;
              font-family: c.$font-sous-menu;
              margin-bottom: 0.5rem;
              text-transform: uppercase;
            }
            .box__input {
              width: 100%;
              margin-bottom: 1rem;
              border: none;
              background-color: white !important;
              height: 2.5rem;
              display: flex;
              align-items: center;
            }
            .box__input.gris {
              width: 100% !important;
              border: none;
              background-color: white !important;
              margin-left: 0;
            }
          }
        }
        &__inputSearch {
          background-color: #4190ee;
          font-size: 2rem;
          color: #fff;
          width: 45%;
          height: 2.5rem;
          margin-top: 1rem;
          cursor: pointer;
          width: 45%;
          align-items: center;
          justify-content: center;
          border-radius: 2rem;
          padding: 0.5rem;
          display: flex;
        }

        .box__input.gris {
          background-color: c.$fond-page-clair !important;
          width: 6rem !important;
          margin-left: 0.5rem;
          .p-inputtext.p-component {
            background-color: c.$fond-page !important;
          }
          .p-placeholder {
            color: c.$gris-picto;
            padding: 0.5rem 3rem 0.5rem 0 !important;
          }
        }
      }
      .box__input {
        max-width: unset;
        box-shadow: 2px 3px 6px lightgray;
        background-color: white !important;
      }
      &__top__boxes__box.top {
        display: none;
      }
    }
  }
}
// Entre 830 et 1200px
@media screen and (max-width: c.$medium) and (min-width: c.$small) {
  .p-accordion-header-text {
    width: 42%;
    .cv_search_bar__container__top__boxes__box.top_mobile {
      width: 100%;
      .p-inputtext.p-component.box__input {
        width: 100% !important;
      }
    }
  }
  .p-inputtext.p-component.box__input {
    height: 2.5rem;
  }

  .p-inputtextarea.p-inputtext.p-component.box__input {
    height: unset;
  }
}
// Entre 1200 et 1480px
@media screen and (max-width: c.$large) and (min-width: c.$medium) {
  .cv_search_bar {
    &__container {
      .box__input {
        border: none;
        max-width: 10rem;
      }
    }
  }
}
// A partir de 1200px
@media screen and (min-width: c.$medium) {
  .p-accordion.p-component {
    display: none;
  }
  .p-multiselect.p-component.p-inputwrapper.box__input {
    box-shadow: none;
  }
  .p-inputtext.p-component.box__input {
    box-shadow: none;
  }
  .cv_search_bar__container {
    width: 100%;
    margin: 0 0;
    &__top {
      &__inputSearch {
        display: flex;
        cursor: pointer;
      }
    }
    &__bottom {
      &__inputSearch {
        display: none;
      }
    }
  }
}
.CV_container {
  .offre_search_bar__container {
    width: 80%;
    margin: 0 10%;
  }
}
@media screen and (min-width: c.$medium) {
  .offre_search_bar {
    &__container {
      &__top {
        &__inputSearch {
          display: none;
        }
      }
      &__bottom {
        &__inputSearch {
          display: flex;
        }
      }
    }
  }
}
