@use "src/Styles/_vars.scss" as c;

.actualites_container {
  a {
    .actualite_container {
      width: 100%;
    }
  }
  p {
    font-family: c.$font-global;
    text-justify: auto;
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 23rem));
    gap: 1rem 1rem;
    grid-auto-rows: auto;
    justify-content: space-evenly;
  }
  &__top {
    h1 {
      color: c.$bleu-paris1 !important;
      font-family: c.$font-gros-titres;
      font-size: xx-large !important;
      padding-top: 4rem;
      margin-bottom: 1rem;
      padding-bottom: 2rem;
    }
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: baseline;
      .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 3.25rem;
      }
      .p-dropdown.p-component.p-inputwrapper.date {
        .p-dropdown-label.p-inputtext.p-placeholder {
          margin-right: 1.4rem;
        }
      }

      .p-accordion .p-accordion-content {
        padding: unset !important;
      }
      .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: unset !important;
      }
      .accordion_actus {
        color: c.$bleu-paris1;
        margin: 0 0 1rem;
        a {
          justify-content: unset;
          padding: 1rem 0 !important;
        }
      }
      .p-accordion-content {
        .actualites_container__top__title__filtres {
          justify-content: flex-start;
          align-items: unset;
        }
      }
    }
  }
  &__article__pagination {
    padding: 2rem 0;
    .p-paginator {
      background: c.$fond-page;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .actualites_container {
    padding: 1rem;
    h1 {
      padding-top: 1rem;
    }
    p {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &__body {
      grid-auto-rows: auto;
    }
    &__top {
      &__title {
        h1 {
          padding-top: 1rem;
        }
      }
    }
  }
}
@media screen and (min-width: c.$small) {
  .actualites_container {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media screen and (max-width: c.$medium) {
  .actualites_container {
    &__top {
      &__title {
        flex-direction: column;
        &__filtres__visible {
          display: none;
        }
      }
    }
  }
}
