// couleurs principales //
$gris: #bebebe;
$noir-rp: #000638;
$orange: #f88c3d;
$bleu-texte-courant: #4a5574;
$bleu-clair: #d7d7eb;
$blanc: #fff;

// couleurs des universités
$bleu-paris1: #00326e;
$bordeau-sorbonne-nouvelle: #8d0e57;
$rouge-upec: #e42535;
$bleu-sorbonne-pn: #13aae3;
$rouge-assas: #d63440;
$rouge-pn: #e4001f;

// couleurs secondaires
$bleu-secondaire: #4190ee;
$gris-picto: #8a8a8a;

// couleurs des mises en avant sur les offres des les alumnis
$fond-alumni: #84e1cb;
$texte-alumni: #4ab79e;

// couleurs des mises en avant sur les nouvelles offres
$fond-nouvelle-offre: #cce2fd;
$texte-nouvelle-offre: #4770da;

// couleurs des mises en avant sur les offres des entreprises partenaires
$fond-entreprise-partenaire: #fff1d6;
$texte-entreprise-partenaire: #eea412;

// couleur de mise en avant du parrainnage sur les utilisateurs
$fond-parrain: #e5c9e3;
$texte-parrain: #a47ba1;

// couleurs des fonds
$fond-page: #f1f3f6;
$fond-page-clair: #fafafa;
$fond-boutons-accueil: #000638c2;

// couleurs des pages communes
$bordeau-commun: #941c56;
$bleu-commun: #132554;

//couleur bordure
$gris-bordure: #eeeeee;

//couleur icone pop-in
$vert-icon: #5eb6a1;

//gris des barres de recherche
$gris-search: #6c757d;

// couleurs des statuts de groupes
$vert-groupe: #99d08b;
$orange-groupe: #ffcb65;
$rouge-groupe: #ef6b64;

// vert partie recruteur
$vert-recruteur: #5eb6a1;
// couleur d'erreur
$erreur: #e24c4c;

// tailles limites
$large: 1480px;
$medium: 1200px;
$normal: 1000px;
$small: 830px;
$tiny: 400px;

// polices
//taille
$font-size-small: 0.8rem;
$font-size-x-small: 0.9rem;
$font-size-normal: 1rem;
$font-size-x-normal: 1.2rem;
$font-size-medium: 1.5rem;
$font-size-large: 2rem;
$font-size-x-large: 2.5rem;

@font-face {
  font-family: "font-gros-titres";
  src: url(../assets/fonts/BebasNeue-Regular.ttf);
}
$font-gros-titres: "font-gros-titres";

@font-face {
  font-family: "font-global";
  src: url(../assets/fonts/Inter-Regular.ttf);
}
$font-global: "font-global";

@font-face {
  font-family: "font-sous-menu";
  src: url(../assets/fonts/Inter-Bold.ttf);
}
$font-sous-menu: "font-sous-menu";

@font-face {
  font-family: "font-date";
  src: url(../assets/fonts/Inter-Thin.ttf);
}
$font-date: "font-date";

@font-face {
  font-family: "font-nav";
  src: url(../assets/fonts/Lato-Bold.ttf);
}
$font-nav: "font-global";

@font-face {
  font-family: "font-actu";
  src: url(../assets/fonts/Lato-Regular.ttf);
}
$font-actu: "font-actu";
