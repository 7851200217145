@use "src/Styles/_vars.scss" as c;

.cardDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  &__top {
    font-size: 0.9rem;
    align-self: flex-start;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    img {
      align-self: center;
      height: 4rem;
      width: 4rem;
      border-radius: 3rem;
      border: 1px solid black;
    }
    .details {
      display: flex;
      flex-direction: column;
    }
  }
  .download_cv {
    color: c.$bleu-secondaire !important;
  }
}

.h3_poste {
  margin: 1rem 0 1rem 0;
}
