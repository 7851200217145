@use "src/Styles/_vars.scss" as c;

.gestion_evenements {
  padding: 0 7rem;
  background-color: c.$fond-page;
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 2.25rem;
  }
  &__title {
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
      margin-bottom: 0.5rem;
    }
    &__filtres {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        max-width: 16rem;
      }
      .membership_input_text {
        font-family: c.$font-global;
        margin-top: -2.5rem;
        padding-left: 2.5rem;
        border: 1px solid c.$gris-picto;
      }

      .p-dropdown.p-component.p-inputwrapper {
        margin: 1rem 0 0 2rem;
        width: 14rem !important;
      }
      .btn-blanc {
        margin: 1rem 0 0 2rem;
        height: 2.5rem;
        width: fit-content !important;
      }
      .searchBar_groupe_finder_icon {
        position: relative;
        color: c.$gris-picto;
        font-size: 1.5rem;
        top: 1.6rem;
        margin-left: 0.7rem;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0;
    &__left {
      width: 30%;
      h3 {
        font-family: c.$font-global;
        font-size: medium;
        text-transform: uppercase;
        color: c.$bleu-paris1;
        font-weight: 800;
      }
      .btn-blanc,
      .btn-bleu {
        margin: 0 0 1rem 0;
      }
      .item1,
      .item2,
      .item3 {
        width: 15rem;
      }
      button:last-of-type {
        margin-bottom: 3rem;
      }

      .evenement_container {
        width: 100%;
        height: fit-content;
        margin: 1rem 0 0 0;
        &__corps,
        &__bottom {
          background-color: white;
        }
        &__corps {
          height: fit-content;
        }
        &__bottom {
          border-bottom-left-radius: 0.33rem;
          border-bottom-right-radius: 0.33rem;
          margin-top: 0;
        }
      }
    }
    &__right {
      width: 65%;
    }
  }
}

.membership_input_search {
  width: 100%;
}
.gestion_depot_offre__title__filtres {
  &__createBtn {
    margin: 1rem 0 0 1rem;
  }
}

@media screen and (max-width: c.$small) {
  .gestion_evenements {
    padding: 0 1rem !important;
    &__title {
      padding-top: 1rem;
      &__filtres {
        flex-direction: column;
        .p-dropdown.p-component.p-inputwrapper {
          margin: 1rem 0 0 0;
          width: fit-content;
        }
        .btn-blanc {
          margin: 1rem 0 0 0;
          height: 2.5rem;
          width: fit-content !important;
        }
      }
    }
    &__body {
      justify-content: center;
      flex-direction: column;
      &__left,
      &__right {
        width: 100%;
        max-width: 100%;
      }
      &__left {
        margin-bottom: 2rem;
        .evenement_container {
          margin: 0;
        }
      }
    }
  }
}
@media screen and (max-width: c.$medium) {
  .gestion_evenements {
    padding: 0 3rem;
    &__title {
      flex-direction: column;
    }
  }
}
