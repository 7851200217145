@use "src/Styles/_vars.scss" as c;
@use "src/index.scss" as font;

.p-slidemenu.p-component.p-slidemenu-overlay {
  left: 0px !important;
  top: 60px !important;
  height: fit-content;
}
.p-slidemenu .p-menuitem {
  height: fit-content !important;
}
.navigation {
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: c.$noir-rp;
  font-family: c.$font-nav;
  justify-content: space-between;

  &__hamburger {
    justify-self: flex-start;
    margin-left: 1rem;
    .p-button-icon.p-c.pi.pi-bars::before {
      font-size: 2rem;
    }
    .p-button.p-component {
      background-color: c.$noir-rp !important;
      color: white;
    }

    .p-button:enabled:hover {
      border-color: c.$noir-rp !important;
      background-color: c.$noir-rp !important;
      color: white !important;
    }
    .p-button:focus {
      box-shadow: unset;
    }
    .slide {
      background-color: red !important;
    }
  }
}

.p-connected-overlay-enter-done {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: c.$fond-page;
}

.p-button.p-component {
  color: c.$noir-rp;
  border: white;
}
.p-menu .p-menuitem-link:hover {
  background-color: c.$noir-rp;
}
.p-menu-list.p-reset {
  width: 100%;
}
.p-menu .p-menuitem-link {
  &:hover {
    background-color: c.$noir-rp !important;
    span {
      color: white !important;
    }
  }
}

.p-menuitem.logoRP_burger {
  background-image: url("../../assets/Reseauprologo.webp");
  background-repeat: no-repeat;
  background-size: contain;
  height: 48px !important;
  width: 180px;
  margin-left: 1.5rem;
  a {
    display: none;
  }
}
.p-menuitem.logoUnif_burger {
  background-image: url("../../assets/logo_unif_petit.webp");
  background-repeat: no-repeat;
  height: 50px !important;
  width: 190px;
  margin-left: 1.5rem;
  margin-top: 0.6rem;
}
.p-slidemenu .p-menuitem-link:hover {
  background-color: c.$noir-rp;
}
.p-slidemenu .p-menuitem-link {
  &:hover {
    background-color: c.$noir-rp !important;
    span {
      color: white !important;
    }
  }
  .p-menuitem-text {
    color: c.$noir-rp !important;
    font-weight: 700;
  }
  .p-submenu-icon {
    color: c.$noir-rp;
  }
}

.p-slidemenu .p-slidemenu-content {
  height: fit-content;
  width: 100%;
}
.p-slidemenu-rootlist.p-active-submenu {
  width: 100% !important;
}
.p-slidemenu-rootlist {
  position: relative !important;
}

.p-slidemenu-wrapper {
  height: fit-content !important;
  width: 100%;
}
.p-submenu-list.p-active-submenu {
  width: 190px !important;
  left: 180px !important;
}
.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  width: 8rem;
  height: 4rem;
}
.img-profil {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-right: 1rem;
  width: 8rem;
  height: 3.5rem;
  &__disconnected {
    height: 2rem;
    margin-right: 1rem;
    width: fit-content;
    color: white;
  }
}

.navigation-menu {
  margin: auto;
  display: flex;
  justify-content: center;
}

.navigation-menu ul {
  display: table;
  width: 100%;
  text-align: center;
  margin-bottom: unset;
}
.navigation-menu li {
  // removes default disc bullet for li tags and applies margin to left & right side
  list-style-type: none;
  margin: 0 2rem;
  display: inline-block;
}
.navigation-menu li a {
  // increases the surface area of the anchor tag to span more than just the anchor text
  text-decoration: none;
  display: block;
  width: 100%;
  color: white;
}
button {
  cursor: pointer;
}
.p-menu-separator {
  background-color: rgb(241, 241, 241);
  height: 1px;
  margin-top: -4px;
  margin-bottom: 4px;
  width: 100%;
}
barre-boutons p {
  font-family: c.$font-sous-menu;
  font-weight: 700;
}

.p-menu.p-component.p-menu-overlay.p-connected-overlay-enter-done {
  z-index: 100000 !important;
}
.p-submenu-header {
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: c.$medium) {
  .navBarProfil {
    .p-menuitem-icon.pi-star {
      visibility: hidden;
    }
    .p-menuitem-icon.pi-star + span {
      position: relative;
    }
    .p-tabmenuitem .p-menuitem-icon.pi-star + span::before {
      display: flex;
      content: url("../../assets/laurier_gris_petit.webp");
      position: relative;
      width: 0.2rem;
      height: 0.2rem;
      z-index: 1000;
      top: -1.5rem;
      left: 3.3rem;
    }
    .p-tabmenuitem.p-highlight .p-menuitem-icon.pi-star + span::before {
      display: block;
      content: url("../../assets/laurier_blanc_petit.webp");
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      z-index: 1000;
      top: -1.5rem;
      left: 3.3rem;
    }
  }
  .img-profil {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-right: 1rem;
    width: 8rem;
    height: 3.5rem;
    border: 2px solid white;
    border-radius: 2rem;
    &__disconnected {
      height: 2rem;
      margin-right: 1rem;
      width: fit-content;
      color: white;
    }
  }
}

// règles d'affichage qui limite l'apparition de la navigation classique
@media screen and (max-width: c.$small) {
  .navigation-menu {
    display: none;
  }
  .navigation {
    height: 40px;
    img {
      height: 100%;
      width: auto;
    }
  }
}
@media screen and (min-width: c.$small) {
  .brand-name {
    display: none;
  }
  .img-profil {
    display: none;
    &__disconnected {
      display: none;
    }
  }
  .navigation__hamburger {
    display: none;
  }
}
