@use "src/Styles/_vars.scss" as c;

.profilBarShowApercu {
  border-radius: 0.33rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 0 0;
  margin: 2rem auto;
  width: fit-content;
  h2 {
    font-family: c.$font-gros-titres;
    margin-bottom: 0;
  }
  h3 {
    font-family: c.$font-sous-menu;
    font-size: 1.2rem;
  }
  p {
    margin-bottom: 1.5rem;
  }

  .p-divider.p-divider-vertical {
    min-height: 80%;
    margin: 0 0.5rem;
  }
  .p-divider.p-divider-horizontal {
    display: none;
  }
  &__top {
    background-color: white;
    margin-bottom: 1rem;
    padding: 2rem;
    display: flex;
    width: 100%;
    &__image {
      width: 8rem;
      height: 8rem;
      margin: 0 2rem;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
        border: 2px solid c.$bleu_paris1;
      }
    }
    // partie description
    &__middle {
      margin: 0 1rem;
      &__parrain {
        background-color: c.$fond-parrain;
        color: c.$texte-parrain;
        border-radius: 0.33rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        width: 8.5rem;
        p {
          text-transform: uppercase;
          margin: 0.5rem 0.5rem 0.5rem 0.8rem;
          font-size: x-small !important;
        }
      }
    }
  }
  &__button {
    margin: auto;
    width: 8rem !important;
    align-items: center;
    svg {
      margin: 0 !important;
      margin-right: 0.5rem !important;
    }
  }
}
.profilBarShowApercu__rightApercu {
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 0.33rem;
  margin-top: 1rem;
  &__item {
    margin-left: 1rem;
    font-size: medium;
    padding-top: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }

    label {
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-left: 0;
      img {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
    p {
      display: flex;
      justify-content: flex-start;
    }
  }
  &__logos {
    img {
      margin-right: 0.5rem;
    }
  }
  // style du texte
  h2 {
    font-family: c.$font-gros-titres;
    text-transform: uppercase;
    font-size: x-large;
  }
  h3 {
    font-family: c.$font-global;
    font-weight: 700;
    font-size: medium;
  }
  p {
    font-family: c.$font-global;
    font-size: small;
    margin-bottom: 0.5rem;
  }
}
.btn_profilBarShowApercu {
  display: flex;
  justify-content: flex-end;
  margin: 8rem 7rem 0 0;
}

@media screen and (max-width: c.$medium) {
  .profilBarShowApercu {
    width: 80%;
    margin: 0 10%;
    &__right {
      width: 80%;
      margin: 2rem 10%;
    }
  }
}
@media screen and (max-width: c.$small) {
  .profilBarShowApercu {
    flex-direction: column;
    margin: 0 1rem;
    height: fit-content;
    width: fit-content;
    box-shadow: 0px 2px 0.2px lightgray;
    .p-divider.p-divider-vertical {
      display: none;
    }
    .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
      display: unset;
      margin-top: 0;
      justify-content: center;
      width: 80%;
    }
    &__image {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &__middle {
      width: fit-content;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      p {
        text-align: center;
      }
    }
    &__rightApercu {
      width: fit-content;
      padding-bottom: 1rem;
      margin: 2rem 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      &__item {
        margin-left: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        p {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
