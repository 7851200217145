@use "src/Styles/_vars.scss" as c;

.basket_resume_item {
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
}

.description_modal__empty__basket {
  padding: 5rem;
  background-color: #fff;
}

.CVPanier__container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  width: 38rem !important;

  .CVPanier {
    background-color: white;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &__image {
      margin-left: 1rem;
      margin-bottom: 1rem;
      max-width: 20%;

      img {
        max-width: 100%;
      }
    }
    &__content {
      width: 70%;
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;
      .trash {
        margin-right: 1rem;
        svg {
          color: c.$bleu-secondaire;
          margin-left: -1rem !important;
          height: 24px;
          width: 24px;
        }
      }
      &__title {
        font-family: c.$font-global !important;
        width: 100%;
        p {
          margin-bottom: unset;
        }
        &__principal {
          display: flex;
          justify-content: space-between;
          font-family: c.$font-sous-menu !important;
        }
        .nombre_cv {
          color: c.$texte-alumni;
          font-style: italic;
        }
      }
    }
  }
}
/* Tooltip text */
.tooltip .tooltiptext {
  font-family: c.$font-global;
  display: none;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  margin-left: 1rem;

  /* Position the tooltip text - see examples below! */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  display: block;
}

.CVNo {
  display: none;
}

.boutons_modal {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.description_modal {
  background-color: c.$fond-page;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CVPanierNo {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: 1px solid black;
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: 460px) {
  .CVPanier__container {
    .CVPanier {
      flex-direction: column;
      justify-content: center;
    }
  }
}
