@use "src/Styles/_vars.scss" as c;

.candidatures_profil {
  padding: 4rem 7rem;
  h1 {
    font-family: c.$font-gros-titres;
    color: c.$noir-rp;
    font-size: xx-large !important;
    margin-top: 1rem;
  }
  h3 {
    font-size: medium;
  }
  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 4rem;
  }
}
