@use "src/Styles/_vars.scss" as c;

//bandeau
.header__bandeau {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: c.$font-global;
  width: 100%;
  padding: 1.2rem 0.5rem;

  &__logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .logo_top {
      width: 7rem;
      height: 3rem;
      padding-right: 2rem;
    }
    .logo_unif {
      width: 5.5rem;
      height: 3rem;
      // margin-left: 2rem;
    }
  }
  &__title {
    width: 55%;
    padding-top: 1rem;

    p {
      text-align: center;
      color: c.$bleu-commun;
      font-size: c.$font-size-x-small;
    }
  }

  &__userDisconnected {
    width: 10rem;
    position: relative;
    button {
      color: c.$bleu-commun;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: none;
      background-color: transparent;
      font-weight: bold;
      padding: 0.5rem;
      border-radius: 5rem;
      transition: all 0.5s;
      &:hover {
        cursor: pointer;
        color: c.$blanc;
        background-color: c.$bleu-commun;
      }
    }
    &__icon {
      font-size: 2rem;
    }

    .connexionInterface {
      position: absolute;
      z-index: 9999;
      width: 12rem;
      background-color: c.$blanc;
      color: c.$bleu-commun;
      margin-left: 2rem;
      padding: 0.5rem;
      border-radius: 1rem;

      li {
        padding: 0.5rem;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .header__bandeau {
    display: none;
  }
}
