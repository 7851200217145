@use "src/Styles/_vars.scss" as c;

.article_presentation {
  padding: 2rem 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .logo_container {
    margin: 4% 20%;
    width: 60%;
  }
  p {
    font-family: c.$font-global;
    font-size: c.$font-size-normal;
    display: block;
    margin: 0.5rem 5.8rem;
    line-height: 1.5rem;
  }
  .presentation_title {
    padding: 0 2rem;
    width: 100%;
    .p-accordion.p-component {
      display: unset !important;
    }
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$noir-rp;
      font-size: xx-large !important;
      margin-bottom: 1rem;
    }
  }
  .logos_univ {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .logo_univ_1 {
      width: 10rem;
      margin-top: 1rem;
      margin-right: 2rem;
      padding: 1rem 1rem;
      box-shadow: 1px 1px 8px 1px c.$gris-bordure;
    }
  }
  .p-accordion.p-component {
    width: 100%;
    .p-accordion-tab {
      width: 100%;
      border: none;
    }
    .p-accordion.p-component {
      border: none;
    }
    .p-accordion-toggle-icon.pi.pi-chevron-down,
    .p-accordion-toggle-icon.pi.pi-chevron-right {
      font-size: 1.5rem;
      margin-left: 3rem;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
      padding: 0;
    }
    .p-accordion-content {
      font-family: c.$font-sous-menu;
    }
    .p-accordion-header-text {
      color: c.$noir-rp;
      font-weight: 500;
      font-size: xx-large !important;
      font-family: c.$font-gros-titres;
      margin-left: 0.7rem;
      display: flex;

      flex-wrap: wrap;
    }
    .p-accordion-header-link {
      background-color: white !important;
      border: none !important;
      box-shadow: none;
    }
    .p-accordion-tab.p-accordion-tab-active {
      box-shadow: none;
    }
    .p-accordion-content {
      border: none !important;
      width: 100%;
      padding: 0;
    }
  }
}
.cardHover_univ {
  margin-top: 1rem;
  margin-right: 2rem;
  padding: 1rem 1rem;
  box-shadow: 1px 1px 8px 1px c.$gris-bordure;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -3rem !important;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  background-color: c.$gris-picto !important;
}

.carousel-inner {
  width: 90% !important;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.carousel-control-next svg,
.carousel-control-prev svg {
  font-size: 2rem;
}

.carousel-item {
  .item-carousel {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 1% 5%;
  }
  img {
    width: 270px;
    aspect-ratio: 9/5;
    margin-top: 1rem;
    padding: 1rem 1rem;
    box-shadow: 1px 1px 8px 1px c.$gris-bordure;
  }
}
.carousel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .article_presentation {
    padding-top: 0;

    p {
      margin: 0.5rem 2rem;
    }
    .article_presentation h1 {
      padding-bottom: 2rem;
    }
    .article_presentation p {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 5rem;
      margin: 0;
    }
    .logos_univ {
      padding-left: 1rem;
      padding-bottom: 1rem;
      justify-content: center;
      padding-right: 1rem;
    }
    .p-accordion-header-text {
      margin-left: 0 !important;

      flex-wrap: wrap !important;
    }
    .p-accordion.p-component {
      width: 100%;
      .p-accordion-tab {
        width: 100%;
        border: none;
        margin-left: 1rem !important;
      }
      .p-accordion-toggle-icon.pi.pi-chevron-down,
      .p-accordion-toggle-icon.pi.pi-chevron-right {
        margin-left: 0 !important;
      }
    }
    #pr_id_3_header_0.p-accordion-header-link {
      padding: 0;
    }
    .article_presentation {
      .logo_container {
        width: 90%;
        margin: 0 5%;
      }
      .presentation_title {
        padding: 0 1rem;
      }
    }
  }

  .carousel-demo {
    width: 70%;
  }

  .carousel-item {
    img {
      width: 128px;
    }
  }
}
