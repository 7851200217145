@use "src/Styles/_vars.scss" as c;

.universities {
  display: flex;
  flex-wrap: wrap;
  img {
    padding: 0.5rem;
    cursor: pointer;
    width: 14rem;
    height: 8rem;
    margin: 1rem;
    box-shadow: 2px 3px 6px lightgray;
    border-radius: 1rem;
    &.isSelected {
      border: 2px solid c.$bleu-paris1;
    }
  }
}
