@use "src/Styles/_vars.scss" as c;

.chiffre {
  font-family: c.$font-sous-menu;
  margin: 0 2rem;

  h1 {
    font-family: c.$font-gros-titres;
    font-size: xxx-large !important;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  p {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .chiffre {
    padding-bottom: 1rem;
    margin: 0 1rem;
  }
}
