@use "src/Styles/_vars.scss" as c;

.gestion_offre_container {
  background-color: white;
  box-shadow: 3px 4px 10px lightgrey;
  height: 8rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gestion_offre_container__info {
    height: 100%;
    cursor: pointer;
    flex-grow: 1;
    margin: 0 1.5rem;
    padding: 1rem 0;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    h5 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      margin-bottom: 0.5rem;
    }
    h1.title {
      color: c.$bleu-paris1;
      font-family: c.$font-sous-menu;
      margin-bottom: 0.5rem;
    }
    &__bottom {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      h2 {
        font-size: smaller;
      }
      color: c.$gris-picto;
    }
    h2 {
      font-size: small;
      margin-right: 0.5rem;
      i {
        margin-right: 0.3rem;
      }
      img {
        height: 1.1rem;
        color: grey;
      }
    }
    .description {
      font-size: small;
      font-weight: 600;
    }
  }

  &__bouton {
    margin-right: 1rem;
    justify-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    width: 7rem;

    span {
      padding: 0 1rem;
      border-radius: 0.33rem;
      display: flex;
      justify-content: center;
    }
    .brouillon {
      background-color: c.$fond-page;
      color: #5b5b5b;
      font-weight: 500;
    }
    .actif {
      background-color: c.$vert-groupe;
      color: #2d4b25;
      font-weight: 500;
    }
    .attente {
      background-color: c.$orange-groupe;
      color: #6b5528;
      font-weight: 500;
    }
    .refuse {
      background-color: c.$rouge-groupe;
      color: #441919;
      font-weight: 500;
    }
    .pourvu {
      background-color: c.$fond-parrain;
      font-weight: 500;
    }
    .desactive {
      color: #4d1c4e;
      background-color: #cf8bd0;
      font-weight: 500;
    }
    .supprime {
      color: #5b5b5b;
      background-color: #f1f3f6;
      font-weight: 500;
    }
  }
}

.p-menu a.p-menuitem-link {
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
}

@media screen and (max-width: c.$small) {
  .gestion_offre_container {
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
    padding: 1rem 0;
    justify-content: center;
    a {
      padding: 0.5rem 1rem;
      width: fit-content;
      .gestion_offre_container__info {
        h5 {
          display: flex;
          justify-content: center;
        }
        &__bottom {
          justify-content: center;
          &__dates {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    &__bouton {
      justify-content: center;
    }
  }
}
@media screen and (max-width: c.$medium) {
  .gestion_offre_container {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
    padding: 1rem 0;
    justify-content: center;
    &__info {
      padding: 0.5rem 1rem;
      width: fit-content;
      h5 {
        display: flex;
        justify-content: center;
      }
      &__bottom {
        justify-content: center;
        flex-wrap: wrap !important;
        &__dates {
          margin-bottom: 0.5rem;
        }
      }
    }
    &__bouton {
      justify-content: center;
    }
  }
}
