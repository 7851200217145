@use "src/Styles/_vars.scss" as c;

.gestion_depot_offre {
  background-color: c.$fond-page;
  padding: 0 7rem;
  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    padding-top: 4rem;
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu-paris1;
      font-size: xx-large !important;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0;
    &__right {
      display: flex;
      flex-direction: column;
      width: 65%;
    }
    &__left {
      display: flex;
      flex-direction: column;
      max-width: 30%;
      min-width: 10rem;
      .btn-blanc,
      .btn-bleu {
        width: fit-content;
        margin-top: 0;
        margin-bottom: 1rem;
      }
      .item1,
      .item2,
      .item3,
      .item4 {
        width: 12rem;
      }
      button:last-of-type {
        margin-bottom: 3rem;
      }

      h3 {
        font-family: c.$font-global;
        font-size: medium;
        text-transform: uppercase;
        color: c.$bleu-paris1;
        font-weight: 800;
      }
      .gestion_offre_container {
        width: 100%;
        height: fit-content;
        margin: 1rem 0 0 0;
        &__corps,
        &__bottom {
          background-color: white;
        }
        &__corps {
          height: fit-content;
        }
        &__bottom {
          border-bottom-left-radius: 0.33rem;
          border-bottom-right-radius: 0.33rem;
          margin-top: 0;
        }
      }
    }
  }
  .p-paginator.p-component {
    background-color: transparent;
  }
}
@media screen and (max-width: c.$medium) {
  .gestion_depot_offre {
    &__container {
      justify-content: center;
      align-items: center;
      &__right {
        padding: 2rem 0;
      }
      .btn-blanc,
      .btn-bleu {
        font-size: small;
        width: 8rem;
      }
    }
    &__title {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: c.$small) {
  .gestion_depot_offre {
    padding: 1rem 1rem;
    &__container {
      padding: 2rem 0 0;
      flex-direction: column;
    }
  }
}
