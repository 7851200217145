@use "src/Styles/_vars.scss" as c;

.h1_footerPage {
  font-family: c.$font-gros-titres;
  color: c.$bleu-commun !important;
  font-size: xx-large !important;
  padding-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 500;
}
.h1_footerPage::before {
  background-color: c.$bleu-commun !important;
  height: 2px;
}

.contactPage {
  padding: 2rem 7rem;
  &__title {
    margin: 4svh 0;
  }
  &__email {
    color: c.$bleu-commun;
    text-decoration: underline;
  }
}

.mentionsPage {
  padding: 2rem 7rem;
  &__title {
    font-size: 1.5rem;
    margin-bottom: 2vh;
    margin-top: 3vh;
  }
  &__contact {
    margin-top: 2svh;
    font-size: 0.9rem;
  }
  &__link {
    color: c.$bleu-commun !important;
    text-decoration: underline !important;
  }
  i {
    font-style: italic;
  }
}
