@use "src/Styles/_vars.scss" as c;

.evenement_detail_container {
  background-color: c.$fond-page;
  &__title {
    padding: 4rem 7rem 2rem;
    font-family: "font-gros-titres";
    color: #00326e;
    font-size: xx-large !important;
    margin-bottom: 1rem;
  }
  #header .menu {
    min-height: 0;
  }
  .p-menu {
    .p-menuitem-link:hover {
      background-color: white !important;
    }
  }
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 7rem;
    &__body {
      background-color: white;
      border-radius: 0.5rem;
      width: 60rem;
      box-shadow: 2px 3px 6px lightgray;
      display: flex;
      flex-direction: column;
      &__img {
        margin: 2rem auto;
        max-width: 70%;
        max-height: 30rem;
      }
      &__texte {
        padding: 1rem;
        & h1 {
          font-size: x-large !important;
          color: c.$bleu-paris1;
          font-weight: bold;
        }
        & h5 {
          font-size: medium;
        }
        &__date {
          color: c.$gris-picto;
          font-family: c.$font-global;
        }
        &__categorie {
          color: c.$gris-picto;
        }
        &__lien {
          color: c.$gris-picto;
          span {
            color: c.$bleu-secondaire;
            cursor: pointer;
          }
        }
        &__inscriptionStatus {
          padding: 0.5rem 0;
          font-family: "font-global";
          font-size: 1rem;
          font-weight: 700;
        }
        &__inscription {
          padding: 0.5rem 0;
          font-family: "font-global";
          font-size: 1rem;
          font-weight: 700;
        }
        &__description {
          overflow-wrap: break-word;
        }
        &__anchor {
          color: c.$bleu-secondaire;
          cursor: pointer;
        }
      }
      &__btns {
        padding: 2rem 0;
      }
    }
  }

  &__bottom {
    padding: 5rem 7rem;
    background-color: c.$fond-page;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    &__title > h1 {
      margin-left: 1rem;
      font-size: x-large !important;
    }

    &__event {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem 1rem;
      grid-template-rows: auto;
      a {
        min-width: 20rem;
        .evenement_container {
          height: fit-content;
          border-radius: 0.33rem;
          &__corps {
            background-color: white;
          }
          &__bottom {
            background-color: white;
            border-bottom-left-radius: 0.33rem;
            border-bottom-right-radius: 0.33rem;
          }
        }
      }
      .actualite_container {
        width: 100%;
        height: fit-content;
      }
    }

    padding-bottom: 4rem;
  }
}
.evenement_detail_questions_container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  &__body {
    padding: 0 1rem 1rem 1rem;
    background-color: white;
    border-radius: 0.5rem;
    width: 60rem;
    box-shadow: 2px 3px 6px lightgrey;
    display: flex;
    flex-direction: column;
    h5,
    h1 {
      color: c.$bleu-paris1;
      font-weight: 600;
      margin-top: 1rem;
    }
    h1 {
      font-size: x-large !important;
      font-weight: bold;
    }
    a:hover {
      color: c.$bleu-secondaire !important;
      transition: 0.2s;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$small) {
  .evenement_detail_container {
    &__top,
    &__bottom {
      padding: 2rem 1rem;
    }

    &__top {
      &__body {
        width: 100%;
        margin-bottom: 2rem;
      }
      &__card {
        width: 100%;
        .evenement_container {
          width: 100%;
          &__corps {
            height: fit-content;
          }
          &__bottom {
            margin: unset;
          }
        }
      }
    }
    &__bottom {
      width: 100%;
      &__event {
        grid-template-columns: 1fr !important;
        width: 100%;
        a {
          width: 100%;
        }
      }
    }
  }
}
// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$medium) {
  .evenement_detail_container {
    &__top {
      &__body {
        width: 100%;
        margin-bottom: 2rem;
      }
      &__card {
        width: 30rem;
        .evenement_container {
          width: 100%;
          &__corps {
            height: fit-content;
          }
          &__bottom {
            margin: unset;
          }
        }
      }
    }
    &__bottom {
      width: 100%;
      &__event {
        grid-template-columns: 1fr 1fr;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: c.$medium) {
  .evenement_detail_container {
    &__title {
      padding: 4rem 1rem 2rem;
    }
  }
}
