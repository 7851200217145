@use "src/Styles/_vars.scss" as c;

.modal__partner__univ {
  min-width: 40%;
}

.universites {
  #header .menu {
    min-height: 30rem;
  }
  &_container {
    padding: 0 7rem 1rem;
    color: c.$noir-rp;
    background-color: c.$fond-page;
    &__top {
      &__title {
        h1 {
          color: c.$noir-rp !important;
          font-family: c.$font-gros-titres;
          font-size: xx-large !important;
          padding-top: 4rem;
        }
      }
      ul {
        list-style-type: disc;
        margin-left: 2rem;
      }
      &__boutons {
        display: flex;
        justify-content: flex-start;
        .btn-bleu,
        .btn-blanc {
          margin: 2rem 0 0 0;
        }
        .btn-bleu {
          margin-right: 1rem;
        }
      }
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          color: c.$noir-rp !important;
          font-family: c.$font-gros-titres;
          font-size: xx-large !important;
          padding-top: 4rem;
        }
        .p-dropdown.p-component.p-inputwrapper {
          margin-top: 4rem;
          width: fit-content !important;
        }
      }
      .no_partner {
        align-self: center;
        margin: 5rem 0;
        font-size: 1.2rem;
      }
      &__entreprises {
        display: flex;
        flex-wrap: wrap;
        a {
          width: fit-content;
          .entrprises_cards {
            margin: 0 2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: c.$small) {
  .universites_container {
    padding: 0 1rem;

    &__top {
      h1 {
        padding-top: 1rem;
      }
    }
    &__bottom {
      padding-bottom: 2rem;
      &__title {
        h1 {
          padding-top: 2rem;
        }
        flex-direction: column;
        .p-dropdown.p-component.p-inputwrapper {
          margin-top: 1rem;
          // width: fit-content !important;
        }
      }
      &__entreprises {
        a {
          width: 100%;
          .entreprises_cards {
            width: 100%;
            margin: 1rem 0;
          }
        }
      }
    }
  }
}
