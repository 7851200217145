@use "src/Styles/_vars.scss" as c;

// Style pour le champ numéro de téléphone
.PhoneInput {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1rem;
  // Champ input
  input.PhoneInputInput {
    width: 100% !important;
    min-width: 100% !important;
  }

  .PhoneInputCountry {
    // On cache le select du pays
    select {
      display: none;
    }

    // Redimensionnement de l'icône du pays
    .PhoneInputCountryIcon {
      img {
        width: 3rem;
        height: 3rem;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.article_actu {
  padding-bottom: 5rem;
  background-color: c.$fond-page;
  .edit_profil__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    padding: 0 7rem;
    h1 {
      font-family: c.$font-gros-titres;
      color: c.$bleu_paris1;
      font-size: xx-large !important;
      margin-bottom: 0;
      padding: 4rem 0 2rem 0;
    }
    .btn-blanc {
      padding: 0 1.5rem 0 1rem;
      svg {
        margin: 0.15rem 1rem 0 0;
      }
    }
    &__informationPersoForm {
      margin-top: 0;
    }
  }
  .form-demo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 2rem;
    padding: 0 20rem;
    .card {
      background-color: c.$fond-page;
      border: none;
    }
    .field__photoProfil {
      margin-top: 2rem;
      .p-float-label {
        margin-bottom: 1rem;
        label {
          margin-left: 5rem;
        }
      }
    }
    .form_columns {
      margin-top: 2vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .field {
        width: 45%;
      }
      .field.ville {
        margin-bottom: 0;
        .listOfAdresses {
          background-color: white;
          display: none;
          position: absolute;
          z-index: 10;
          .searchedAdress {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            cursor: pointer;
            &:hover {
              cursor: pointer;
              .adress,
              .department {
                color: c.$bleu_paris1;
              }
            }
            .adress {
              font-weight: bold;
            }
            .department {
              color: #6c757d;
              font-family: "font-global";
              font-size: medium;
            }
          }
        }
        &:hover {
          .listOfAdresses {
            display: block !important;
            border-radius: 1rem;
            padding: 0.25rem;
          }
        }
      }
    }
    .field {
      margin-top: 2vh;
      label {
        margin-left: 0.5rem;
      }
      input {
        color: black;
      }
      h2 {
        margin: 0 0 0.5rem 1rem;
        font-size: large;
        color: c.$bleu_paris1;
        font-family: c.$font-sous-menu;
        &.p-error {
          color: #e24c4c;
        }
      }
    }
    .field.checkbox {
      margin-top: 1rem;
      width: unset;
      display: flex;
      align-items: center;
      .p-checkbox.p-component {
        margin-top: 0 !important;
      }
      &.companyCreator {
        flex-direction: column;
        justify-content: center;
        align-items: unset;
        margin-top: 3rem;
        h3 {
          font-size: large;
          color: c.$bleu_paris1;
        }
        .companyCreator__child {
          display: flex;
          align-items: center;
        }
      }
    }
    .form_boutons {
      input {
        margin-top: 5vh;
        margin-bottom: 0;
      }
      margin-top: 0 !important;
      display: flex;
      justify-content: center;
    }
  }

  .p-dropdown.p-component.p-inputwrapper {
    background-color: white;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .article_actu {
    .edit_profil__title {
      padding: 0 1rem;
    }
  }
  .PhoneInput {
    input {
      width: 75% !important;
      font-size: smaller !important;
    }
  }
  .PhoneInputCountryIconImg {
    display: none;
  }
  .form-demo {
    padding: 0 !important;
  }

  .form_boutons {
    input {
      margin-bottom: 2vh;
    }
  }
}
@media screen and (min-width: c.$small) {

}
