@use "src/Styles/_vars.scss" as c;

.CVNo {
  display: none;
}
.CV__container {
  margin: 1rem 0;
  background-color: white;
  width: 35rem;
  cursor: default !important;
  .CV {
    display: flex;
    .details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1.5rem 0.5rem 0;
      .h2_name {
        font-size: medium;
        font-weight: 700;
        margin-bottom: 0.2rem;
      }
      .h3_poste {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: medium;
      }
      .left {
        img {
          padding-left: 0.3rem;
          margin-left: 0.2rem;
          margin-bottom: 0.3rem;
        }
        .bottom {
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
          .show_profil {
            color: c.$texte-alumni !important;
            font-weight: 600;
            font-size: medium;
          }
          .dwnld_cv {
            cursor: pointer;
            color: c.$bleu-secondaire !important;
            font-weight: 600;
            font-size: medium;
            margin-left: 1.5rem;
            svg {
              margin-right: 0.3rem !important;
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 2rem;
        position: relative;
        margin-top: -0.2rem;
        svg {
          color: c.$bleu-secondaire;
          height: 1.5rem;
          width: 1.5rem;
        }
        .loaderContainer {
          padding: 0;
          &__loader,
          &__loader::after {
            width: 2rem !important;
            height: 2rem !important;
          }
        }
      }
    }
    &__image {
      display: flex;
      align-items: center;
      padding-right: 1rem;
      justify-content: left;
      img {
        height: 4rem;
        width: 4rem;
        border-radius: 3rem;
        border: 1px solid black;
      }
      .user__alumnis {
        height: 1.8rem;
        width: 1.8rem;
        position: relative;
        border: none;
        z-index: 1;
        top: -1rem;
        left: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
/* Tooltip text */
.tooltip .tooltiptext {
  font-family: c.$font-global;
  display: none;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 2;
  right: 30px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  display: block;
}
.tooltip {
  opacity: 1 !important;
}

@media screen and (min-width: c.$medium) and (max-width: c.$large) {
  .CV__container.from_cv {
    .CV .details .left {
      margin-right: 3rem;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: c.$medium) {
  .CV__container {
    margin-bottom: 1rem;
    .CV {
      justify-content: center;
      padding: 0 0.5rem;
      &__content {
        margin: 1rem 0 1rem 1rem;
        &__title {
          display: flex;
          justify-content: space-around;
        }
        &__sub_title {
          display: flex;
          justify-content: center;
        }
      }
      &__logos {
        height: 1rem;
        flex-direction: row;
      }
    }
    .CV__footer {
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: c.$small) {
  .CV__container.from_cv {
    width: 100% !important;
  }
}
