@use "src/Styles/_vars.scss" as c;

.accueil {
  &:nth-child(odd) {
    background-color: c.$fond-page;
  }
  p {
    font-family: c.$font-global;
    text-justify: auto;
  }
  .article_candidatures {
    background-color: c.$fond-page;
  }
  .article_chiffres_cles {
    background-color: c.$bleu-secondaire;
  }
  .article_evenements {
    background-color: c.$fond-page;
  }
  .article_actualites {
    background-color: white !important;
    .actualites_container {
      &__pagination {
        margin-bottom: 4rem;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.head__title {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 5rem;
  padding: 0 12rem;
  span {
    padding: 1rem;
    color: c.$blanc;
    font-family: c.$font-gros-titres;
    font-size: xx-large !important;
  }
}

@media screen and (min-width: c.$small) {
  #header .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    &__connected {
      width: 100%;
    }
    &__notConnected {
      width: 100%;
    }
  }
}
@media screen and (max-width: c.$small) {
  .accueil {
    .article_partenaires {
      padding-top: 0;
    }
  }
}

// modifications d'affichage en passant sur mobile
@media screen and (max-width: 440px) {
  #header .menu {
    min-height: fit-content;
  }
  .accueil {
    h1 {
      margin-bottom: 0;
    }
    .article_offre_container {
      padding-bottom: 0;
    }
    .btn-bleu {
      height: fit-content;
    }
    .btn-transparent {
      height: fit-content;
    }
    .offre {
      height: fit-content;
      &__date {
        margin-top: 0rem;
      }
    }
    .article_partenaires {
      padding-top: 0;
    }
  }
}
