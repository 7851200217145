@use "src/Styles/_vars.scss" as c;

.pagination {
  padding: 2.5rem 0 3rem 0;
  display: flex;
  justify-content: center;

  .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
    display: none;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: c.$fond-page;
    color: c.$bleu-paris1;
  }
  .pagination .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border: c.$bleu-paris1;
  }
  .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.05rem c.$bleu-paris1;
  }
  .p-paginator-current {
    display: none;
  }

  [type="button"]:not(:disabled),
  [type="button"]:not(:disabled) {
    color: c.$bleu-paris1;
  }
  .p-paginator-left-content,
  .p-paginator-right-content {
    margin: 0;
  }
  .p-paginator {
    background: transparent;
  }
}

@media screen and (max-width: c.$small) {
  .pagination {
    .p-paginator-next.p-paginator-element.p-link {
      justify-self: flex-start;
    }
    .p-paginator-pages {
      display: flex;
      justify-content: center;
    }
    .p-paginator {
      padding: 0;
    }
    .p-paginator-left-content,
    .p-paginator-right-content {
      display: none;
    }
  }
}
